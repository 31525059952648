import { TurningOffDuration, TurningOffReason } from "@/interfaces";

export default {
  pickRestaurant: 'Wybierz restauracje',
  removeConfirm: 'Czy na pewno chcesz usunąć restaurację oraz wszystkie jej dane?',
  restaurants: 'Restauracje',
  statuses: 'Statusy restauracji',

  integrationOnConfirm: 'Czy na pewno chcesz włączyć integrację?',
  integrationOffConfirm: 'Czy na pewno chcesz wyłączyć integrację?',

  turnOn: 'Czy na pewno chcesz włączyć restaurację?',
  turnOff: 'Czy na pewno chcesz wyłączyć restaurację?',

  editConcept: 'Edycja konceptu',
  addConcept: 'Dodawanie konceptu',

  copy: 'Kopiowanie restauracji',
  newName: 'Nowa nazwa',

  add: 'Tworzenie nowej restauracji',
  edit: 'Edycja danych restauracji',

  name: 'Nazwa',
  logo: 'Logo',
  desc: 'Opis',
  prepTime: 'Czas przygotowania posiłku',
  externalRestaurant: 'Zewnętrzna restauracja',

  editWht: 'Edycja godzin otwarcia',
  from: 'Od',
  to: 'Do',

  days: ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'],

  concepts: 'Koncepty',
  confirmRemConcept: 'Czy na pewno chcesz usunąć koncept z aktualnej lokalizacji?',

  confirmRemove: 'Czy na pewno chcesz usunąć restaurację oraz wszystkie jej dane?',

  wh: 'Godziny pracy',

  conceptAdded: 'Koncept został dodany',
  conceptRemovedFromLoc: 'Koncept został usunięty z lokalizacji',
  whChanged: 'Godziny pracy zostały zmienione',
  avabilityChanged: 'Dostępność została zmieniona',
  syncStarted: 'Synchronizacja została rozpoczęta',
  restaurantOn: 'Restauracja została włączona',
  restaurantOff: 'Restauracja została wyłączona',
  integrationOn: 'Integracja została włączona',
  integrationOff: 'Integracja została wyłączona',
  locationOn: 'Lokalizacja została włączona',
  locationOff: 'Lokalizacja została wyłączona',

  removed: 'Restauracja została usunięta',
  added: 'Restauracja została dodana',

  restaurantTypes: [
    { text: 'restauracja', value: 'restaurant' },
    { text: 'sklep', value: 'shop' },
  ],

  restaurantIsOn: 'Restauracja jest włączona',
  restaurantIsOff: 'Restauracja jest wyłączona',

  turningOffReasons: {
    'busy': 'duży ruch w restauracji',
    'hardware_failure': 'awaria sprzętu kuchennego',
    'internet_problem': 'problemy z Internetem',
    'missing_items': 'brak dostępnosci produktów',
    'changed_by_marketplace': 'zmienione przez marketplace',
    'holidays': 'urlop',
    'auto': 'zmieniono automatycznie'
  },

  turningOffDurations: {
    'permanent': 'do odwołania',
    '1h': '1h',
    '2h': '2h',
    '3h': '3h',
    'endOfDay': 'do końca dnia',
  },

  turningOffHeaders: {
    turnOffDuration: 'czas wyłączenia',
    turnOffReason: 'powód wyłączenia',
  },
}
