import { formatPrice } from '@/helpers/price';
import { OrderItemMod } from '@/interfaces';

export class OrderItemModModel implements OrderItemMod {
  qua!: number;
  value!: number;
  mods?: OrderItemMod[] | undefined;
  category!: string;
  name!: string;

  constructor(data: Partial<OrderItemMod>, private currencyFormat: string) {
    Object.assign(this, data);

    this.value = +this.value;

    this.mods = this.mods && this.mods.map(item => new OrderItemModModel(item, this.currencyFormat));
  }

  getValueWithCurrency(): string {
    return formatPrice(this.value, this.currencyFormat);
  }

  getSingleValueWithCurrency(): string {
    return formatPrice(this.value / this.qua, this.currencyFormat);
  }
}
