import { Nullable } from '@/interfaces';
import ApiService from './api';

interface LoginPayload {
  username: string;
  password: string;
}

interface LoginResponse {
  access_token: string;
}

class AuthService extends ApiService{
  async login(username: string, password: string): Promise<boolean> {
    try {
      const response = await this.post<LoginResponse, LoginPayload>('auth/login', { username, password });
      if (response?.access_token) {
        localStorage.setItem('at', response.access_token);
        this.updateAccessToken(response.access_token);
        return true;
      }
    // eslint-disable-next-line no-empty
    } catch(err){ }

    return false;
  }

  async reset(email: string, lang: string): Promise<Nullable<boolean>> {
    return this.post('auth/reset', { email, lang });
  }

  setNewPassword(token: string, password: string): Promise<Nullable<boolean>> {
    return this.patch('auth/setnew', { token, password });
  }
}

export default new AuthService
