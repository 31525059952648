
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { NotificationType } from '@/interfaces';

  const notificationStore = namespace('notification');

  @Component
  export default class Notification extends Vue {
    @notificationStore.Getter('getType')
    readonly type!: NotificationType;

    @notificationStore.Getter('getMessage')
    readonly message!: string;

    get isVisible(): boolean {
      return Boolean(this.message);
    }

    set isVisible(val: boolean) {
      !val && this.resetNotification()
    }

    @notificationStore.Mutation
    resetNotification!: () => void
  }
