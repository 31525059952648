export default {
  pickRestaurant: 'Виберіть ресторан',
  removeConfirm: 'Ви впевнені, що бажаєте видалити ресторан та всі дані?',
  restaurants: 'Ресторани',
  statuses: 'Статус ресторанів ',

  integrationOnConfirm: 'Ви впевнені, що хочете ввімкнути інтеграцію?',
  integrationOffConfirm: 'Ви впевнені, що хочете вимкнути інтеграцію?',

  turnOn: 'Ви впевнені, що хочете ввімкнути ресторан?',
  turnOff: 'Ви впевнені, що хочете вимкнути ресторан?',

  editConcept: 'Редагувати концепт',
  addConcept: 'Додати концепт ',

  copy: 'Копіювання ресторану ',
  newName: 'Нова назва',

  add: 'Створення нового ресторану',
  edit: 'Редагування даних ресторану',

  name: 'Назва',
  logo: 'Лого',
  desc: 'Опис',
  prepTime: 'Час приготування страв',
  externalRestaurant: 'Зовнішній ресторан',

  editWht: 'Редагувати години роботи ресторану ',
  from: 'З',
  to: 'До',

  days: ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота', 'Неділя'],

  concepts: 'Концепти ',
  confirmRemConcept: 'Ви впевнені, що хочете видалити концепт із вибраного ресторану?',

  confirmRemove: 'Ви впевнені, що хочете видалити ресторан та всі його дані?',

  wh: 'Години роботи',

  conceptAdded: 'Концепт було додано',
  conceptRemovedFromLoc: 'Концепт було видалено із цієї локації',
  whChanged: 'Години роботи було змінено',
  avabilityChanged: 'Доступність було змінено',
  syncStarted: 'Синхронізацію запущено',
  restaurantOn: 'Ресторан увімкнено',
  restaurantOff: 'Ресторан вимкнено',
  integrationOn: 'Інтеграцію увімкнено',
  integrationOff: 'Інтеграцію вимкнено',
  locationOn: 'Локацію увімкнено',
  locationOff: 'Локацію вимкнено',

  removed: 'Ресторан видалено ',
  added: 'Ресторан додано ',

  restaurantTypes: [
    { text: 'ресторан', value: 'ресторан' },
    { text: 'магазин', value: 'магазин' },
  ],

  turningOffReasons: {
    'busy': 'повна посадка у закладі',
    'hardware_failure': 'проблеми з устаткуванням ',
    'internet_problem': 'проблеми з інтернетом',
    'missing_items': 'відсутня необхідна продукція ',
    'changed_by_marketplace': 'змінено через маркетплайс',
    'holidays': 'вихідний день',
    'auto': 'змінено автоматично',
  },

  turningOffDurations: {
    'permanent': 'на невизначений час',
    '1h': '1h',
    '2h': '2h',
    '3h': '3h',
    'endOfDay': 'end of day',
  },

  turningOffHeaders: {
    turnOffDuration: 'період вимкення',
    turnOffReason: 'причина вимкнення',
  },
}
