import { getLocale } from '@/helpers/user';
import { LocationStats, MenuItemStat, Nullable, Order, OrdersFilters, OrdersStatusNo, OrderStatus, PlaceStats, RawOrder, RejectionReasons } from '@/interfaces';
import { OrderModel } from '@/models';
import apiService from './api';

interface FromToDate {
  from: string | null;
  to: string | null;
}

const transformDates = (dates: string[]): FromToDate  => {
  return {
    from: dates[0] || null,
    to: dates[1] || null
  }
}

class OrdersService extends apiService{
  async findAll(filters: OrdersFilters): Promise<Nullable<OrderModel[]>> {
    const orders = await this.post<Order[], OrdersFilters>('orders', filters);
    return orders 
      ? orders.map((item: Order) => new OrderModel(item, getLocale()))
      : null;
  }

  changeStatus(orderId: number, status: OrderStatus, reason?: RejectionReasons, time?: number): Promise<Nullable<Pick<OrderModel, 'status' | 'pickupTime'>>> {
    return this.patch(`orders/changestatus/${ orderId }`, {
      status,
      reason,
      time,
    });
  }

  getTopMenuItems(restaurantId: number): Promise<Nullable<MenuItemStat[]>> {
    return this.get(`orders/topItems/${ restaurantId }`);
  }

  getLocationsStats(rangeId: number, dates: string[], showEmpty?: boolean, sortDirection?: string, sortKey?: string, showInternal?: boolean): Promise<Nullable<LocationStats[]>> {
    return this.post('orders/stats/locations', { rangeId, ...transformDates(dates), getEmptyType: showEmpty, sortDirection, sortKey, showInternal });
  }

  getRestaurantsStats(rangeId: number, dates: string[]): Promise<Nullable<PlaceStats[]>> {
    return this.post('orders/stats/restaurants', { rangeId, ...transformDates(dates) });
  }

  getMenuStats(rangeId: number, dates: string[]): Promise<Nullable<LocationStats[]>> {
    return this.post('orders/stats/menu', { rangeId, ...transformDates(dates) });
  }

  getRawData(id: string): Promise<Nullable<RawOrder[]>> {
    return this.get(`providers/raw/${ id }`);
  }

  async orderCourier(orderId: number): Promise<Nullable<boolean>> {
    return this.get(`couriers/order/${ orderId }`);
  }

  getOrdersNoByStatus(): Promise<Nullable<OrdersStatusNo[]>> {
    return this.get('orders/statsbystatus');
  }

  async findOne(orderId: number): Promise<Nullable<OrderModel>> {
    const order = await this.get<Order>(`orders/${ orderId }`);
    return order && new OrderModel(order, getLocale());
  }
}

export default new OrdersService
