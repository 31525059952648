import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { WsService } from '@/services';
import { Nullable } from '@/interfaces';

@Module({ namespaced: true })
export default class WsStore extends VuexModule {
  @Action
  public sendInfoAboutNewVersion(hard: boolean): Promise<Nullable<boolean>> {
    return WsService.sendInfoAboutNewVersion(hard);
  }

  @Action
  public sendTestOrder(orderId: number): Promise<Nullable<boolean>> {
    return WsService.sendTestOrder(orderId);
  }
}
