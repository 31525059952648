import {
  Concept,
  Restaurant,
  SingleStoreStatus,
  SupportedProviders,
  WorkingHour,
} from '@/interfaces';
import { LocationModel } from '.';

export class ConceptModel implements Concept {
  id!: number;
  locationId!: number;
  locationName?: string;
  name: string;
  location?: LocationModel;
  restaurant?: Restaurant;
  restaurantId?: number;
  ue_storeId?: string;
  own_storeId?: string;
  last_sync?: string;
  glovo_storeId?: string;
  stava_storeId?: string;
  wolt_storeId?: string;
  wolt_authToken?: string;
  wolt_apiKey?: string;
  bolt_storeId?: string;
  uberEats_link?: string;
  socialFood_link?: string;
  glovo_link?: string;
  wolt_link?: string;
  bolt_link?: string;
  stava_link?: string;
  justEat_link?: string;
  whs?: WorkingHour[];

  storeStatus?: SingleStoreStatus;

  constructor(data: Partial<Concept>) {
    Object.assign(this, data);

    this.name = data.restaurant?.name || '';

    this.location = data.location && new LocationModel(data.location);
    this.locationName = this.location?.name;

    if (this.whs?.length) {
      this.whs = this.whs.sort((a, b) => (a.day < b.day ? -1 : 1));
    }
  }

  getCurrencyFormat(): string {
    return this.location?.getCurrencyFormat() || '';
  }

  hasWorkingHours(): boolean {
    return !!this.whs?.length;
  }

  setStoreStatus(storeStatus: SingleStoreStatus): void {
    this.storeStatus = storeStatus;
  }

  storeIsEnabledIn(provider: SupportedProviders): boolean {
    return !!this.storeStatus?.[provider]?.status;
  }

  hasStoreStatus(provider: SupportedProviders): boolean {
    return this.storeStatus?.[provider] !== undefined;
  }

  get hasAtLeastOneEnabledStore(): boolean {
    return Object.values(this.storeStatus || {})
      .map((item) => item.status)
      .some((val) => val);
  }

  get areAllStoresEnabled(): boolean {
    return Object.values(this.storeStatus || {})
      .map((item) => item.status)
      .every((val) => val);
  }
}
