import { SortReturnValues } from "@/interfaces";
import { sortStringByName } from "./array";

type HasTextProperty = Required<{ text: string }>;

export function sortByName(a: string | HasTextProperty, b: string | HasTextProperty): SortReturnValues {
  if (typeof a === 'string' && typeof b === 'string') {
    return sortStringByName(a, b);
  } else if (typeof a === 'object' && typeof b === 'object') {
    return (a?.text && b?.text) ? sortStringByName(a.text, b.text) : 0;
  }

  return 0;
}
