export default {
  statsBy: 'Statystyki zamówień wg',
  orders: 'Zamówienia',
  locations: 'lokalizacji',
  restaurants: 'restauracji',
  menu: 'menu',
  today: 'dziś',
  lastWeek: 'ostatni tydzień',
  lastMonth: 'ostatni miesiąc',
  custom: 'zakres ręczny',
  location: 'Lokalizacja',
  restaurant: 'Restauracja',
  menuItem: 'Pozycja z menu',
  showEmpty: 'Pokaż tylko puste',
  showInternal: 'Pokaż restauracje wewnętrzne'
}
