export default {
  add: 'Dodawanie pliku',
  edit: 'Edycja pliku',

  name: 'Nazwa',
  file: 'Plik',

  files: 'Pliki',
  confirm: 'Czy na pewno chcesz usunąć wybrany plik?',

  removed: 'Plik został usunięty',
  added: 'Plik został dodany'
}
