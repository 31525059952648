export default {
  login: 'Login',
  email: 'E-mail adress',
  password: 'Password',
  dontRemember: 'Do not you remember the password?',
  loginBtn: 'Login',

  remind: 'Password reminding',
  reset: 'Reset the password',

  setNewPassword: 'Setting a new password',
  repeatPassword: 'Retype password',
  save: 'Save',

  noCredentials: 'You do not have sufficient permissions',
  wrongData: 'The data provided is incorrect',
  instructions: 'Instructions on how to set up a new password have been sent to the e-mail address provided',
  noUser: 'The specified e-mail does not exist in our database',
  passChanged: 'The password has been changed',
  passChangedError: 'Password could not be changed',
}
