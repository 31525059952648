export interface RestaurantFeedFilters {
  type?: RestaurantFeedFiltersType[];
  locationId?: number | null;
  conceptsIds?: number[];
  countries?: string[];
  date?: string;
}

export enum RestaurantFeedFiltersType {
  product = 'product',
  restaurant = 'restaurant',
}
