export default {
  nextPage: 'next page',
  areYouSure: 'Are you sure?',

  newVersion: 'A new version of the application is available. Click to refresh.',
  offline: 'You are offline. Check your connection to the Internet.',

  all: 'all',

  cancel: 'Cancel',
  save: 'Save',

  landscape: 'Rotate the tablet to fully enjoy the application',

  youROffline: 'you are offline',
  checkConnection: 'check your internet connection',

  interaction: 'Confirm tablet identification on the interactive device <br /><br />Tap',

  404: 'This page does not exist',
  500: 'An error occurred while processing your request. Please try again in a moment',

  country: 'Country',
  notificationLanguage: 'Notifications language',
  type: 'Type',
  name: 'Name',

  dataSaved: 'Data has been saved',

  turningOn: 'Your restaurant {name} will be turned on at {time}',
};
