import { OrderDelivery, OrderDeliveryPlace, OrderDeliveryStatus, OrderDeliveryStatusHistory } from "@/interfaces";
import { OrderDeliveryStatusHistoryModel } from ".";

export class OrderDeliveryModel implements OrderDelivery {
  id!: number;
  courierName?: string;
  courierPhone?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
  notes?: string;
  unit?: string;
  type?: OrderDeliveryPlace;
  externalId?: string;
  externalId2?: string;
  street?: string;
  no?: string;
  zip?: string;
  city?: string;
  status?: OrderDeliveryStatus;
  history?: OrderDeliveryStatusHistory[];

  public constructor(data: Partial<OrderDelivery>) {
    Object.assign(this, data);

    if (data?.history?.length) {
      this.history = data.history.map(item => new OrderDeliveryStatusHistoryModel(item));
    }
  }
}
