import { Restaurant, RestaurantType } from '@/interfaces';

export class RestaurantModel implements Restaurant {
  id!: number;
  name!: string;
  logo?: string | undefined;
  desc?: string | undefined;
  prepTime?: number | undefined;
  type!: RestaurantType;
  country!: string;
  brandId!: number;
  external!: boolean;

  public constructor(location: Partial<Restaurant>) {
    Object.assign(this, location);
  }
}
