export default {
  login: 'Login',
  email: 'Endereço de e-mail',
  password: 'Senha',
  dontRemember: 'Não se lembra da senha?',
  loginBtn: 'Registro',

  remind: 'Retirar a senha',
  reset: 'Redefinir a senha',

  setNewPassword: 'Definir uma nova senha',
  repeatPassword: 'Reescrever senha',
  save: 'Guardar',

  noCredentials: 'Não tem permissões suficientes',
  wrongData: 'Os dados fornecidos são incorrectos',
  instructions: 'Instruções sobre como criar uma nova senha foram enviadas para o endereço de e-mail fornecido',
  noUser: 'O e-mail especificado não existe na nossa base de dados',
  passChanged: 'A senha foi alterada',
  passChangedError: 'A senha não pôde ser alterada',
}
