import Vue from 'vue'
import Vuex from 'vuex'

import restaurants from './restaurants';
import notification from './notification';
import files from './files';
import menu from './menu';
import locations from './locations';
import concepts from './concepts';
import users from './users';
import stats from './stats';
import orders from './orders';
import app from './app';
import ws from './ws';
import logs from './logs';
import resources from './resources';
import holidays from './holidays';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    restaurants,
    notification,
    files,
    menu,
    locations,
    concepts,
    users,
    stats,
    orders,
    app,
    ws,
    logs,
    resources,
    holidays,
  }
})
