export default {
  add: 'Acrescentar uma categoria de menu',
  edit: 'Categorias de menu de edição',

  name: 'Nome',
  type: 'Tipo de categoria',
  limits: 'Limites de quantidade',
  from: 'a partir de',
  to: 'para',
  reset: 'produtos de preço zero',

  productsCat: 'categoria de produto',
  modsCat: 'categoria de modificadores',

  addItem: 'Criação de um item de menu',
  editItem: 'Editar item do menu',
  choose: 'Seleccionar produtos existentes',
  addNew: 'ou adicionar um novo',
  products: 'Produtos',

  photo: 'Foto',
  desc: 'Descrição',
  qua: 'Peso/quantidade',
  price: 'Preço',
  mods: 'Modificadores',

  menu: 'Menu',
  confirmRemove: 'Tem a certeza de que quer apagar o item seleccionado?',
  confirmRemoveCategory:
    'Tem a certeza de que pretende eliminar a categoria seleccionada e todos os artigos que ela contém?',

  importCsv: 'Seleccione o ficheiro CSV a partir do menu para importar',
  confirmImport:
    'A importação de um novo menu irá apagar o menu actual (incluindo fotos e modificadores). Tem a certeza de querer continuar?',
  chhoseCSV: 'Por favor seleccione um ficheiro CSV',

  accessibility: 'Disponibilidade do menu',

  top: 'Pratos de topo',

  catRemoved: 'A categoria foi eliminada',
  catAdded: 'A categoria foi acrescentada',
  itemRemoved: 'O item do menu foi eliminado',
  itemAdded: 'Foi adicionado um item de menu',
  itemAddedToCat: 'Os produtos foram adicionados às categorias',
  menuImported: 'O menu foi importado',

  copyCategory: 'Copy menu category',
  categoryCopied: 'Category has been copied',
  categoryCopiedError: 'Copying category failed',
  copyWithMods: 'Copy with mods',

  excludeFromSync: 'exclude from sync',
  requiredMenuItem: 'require item',

  turnOnSelected: 'turn on selected',
  turnOffSelected: 'turn off selected',
  turnOffPermanent: 'permanently',
  turnOffToday: 'until end of the day',
};
