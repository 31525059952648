import { AllStoreStatuses, Nullable, SupportedProviders, TurningOffReason } from '@/interfaces';
import { ChangeConceptStatusPayload, ChangeStatusLocationPayload } from '@/store/interfaces';
import apiService from './api';

export type JobState =
  | 'completed'
  | 'failed'
  | 'active'
  | 'delayed'
  | 'prioritized'
  | 'waiting'
  | 'waiting-children'
  | 'unknown';
export type RestaurantStatusQueue = Record<SupportedProviders, JobState>;

class ProvidersService extends apiService {
  getList(): Promise<Nullable<SupportedProviders[]>> {
    return this.get('providers/getList');
  }

  synchronizeData(
    conceptId: number,
    provider: SupportedProviders | null,
    scheduledTime: string
  ): Promise<Nullable<boolean>> {
    return this.get(`providers/sync/${conceptId}/${provider}/${scheduledTime}`);
  }

  synchronizeAllConcepts(
    restaurantId: number,
    provider: SupportedProviders | null,
    scheduledTime: string
  ): Promise<Nullable<boolean>> {
    return this.get(`providers/syncall/${restaurantId}/${provider}/${scheduledTime}`);
  }

  synchronizeLocation(
    restaurantId: number,
    provider: SupportedProviders | null,
    scheduledTime: string
  ): Promise<Nullable<boolean>> {
    return this.get(`providers/synclocation/${restaurantId}/${provider}/${scheduledTime}`);
  }

  changeStatus(
    id: number,
    data: Omit<ChangeConceptStatusPayload, 'id'>
  ): Promise<Nullable<boolean>> {
    return this.patch(`providers/restaurantStatus/${id}`, data);
  }

  changePosStatus(
    id: number,
    status: boolean,
    provider?: SupportedProviders
  ): Promise<Nullable<boolean>> {
    return this.patch(`providers/posStatus/${id}`, {
      status,
      provider,
    });
  }

  getRestaurantStatus(
    conceptId: number
  ): Promise<Nullable<Partial<Record<SupportedProviders, boolean>>>> {
    return this.get(`providers/restaurantStatus/${conceptId}`);
  }

  getRestaurantStatusForUser(): Promise<Nullable<Partial<Record<SupportedProviders, boolean>>>> {
    return this.get('providers/restaurantStatusForUser');
  }

  getPosStatus(conceptId: number): Promise<Nullable<Record<SupportedProviders, boolean>>> {
    return this.get(`providers/posStatus/${conceptId}`);
  }

  async regenerateThumbnails(
    provider: SupportedProviders | null,
    conceptId?: number
  ): Promise<void> {
    await this.get(`providers/regenerateThumbnails/${provider}/${conceptId}`);
  }

  changeStatusLocation(
    locationId: number,
    data: Omit<ChangeStatusLocationPayload, 'locationId'>
  ): Promise<Nullable<boolean>> {
    return this.patch(`providers/statusLocation/${locationId}`, data);
  }

  getRestaurantStatusQueue(conceptId: number): Promise<Nullable<RestaurantStatusQueue>> {
    return this.get(`providers/restaurantPOSStatusQueue/${conceptId}`);
  }
}

export default new ProvidersService();
