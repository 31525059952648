export default {
  pickRestaurant: 'Choose restaurants',
  removeConfirm: 'Are you sure you want to delete the restaurant and all its data?',
  restaurants: 'Restaurants',
  statuses: 'Status of restaurants',

  integrationOnConfirm: 'Are you sure you want to turn on integration?',
  integrationOffConfirm: 'Are you sure you want to disable integration?',

  turnOn: 'Are you sure you want to turn on the restaurant?',
  turnOff: 'Are you sure you want to switch off the restaurant?',

  editConcept: 'Edit concept',
  addConcept: 'Creating a new concept',

  indicator: 'Concept alive indicator',

  masterSwitchLabel: 'Concept switch turn on / off',

  copy: 'Restaurant copying',
  newName: 'New name',

  add: 'Creating a new restaurant',
  edit: 'Editing restaurant data',

  name: 'Name',
  logo: 'Logo',
  desc: 'Description',
  prepTime: 'Meal preparation time',
  externalRestaurant: 'External restaurant',

  editWht: 'Edit opening hours',
  from: 'From',
  to: 'Until',

  days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],

  concepts: 'Concepts',
  confirmRemConcept: 'Are you sure you want to remove the concept from your current location?',

  confirmRemove: 'Are you sure you want to delete the restaurant and all its data?',

  wh: 'Working hours',

  conceptAdded: 'The concept has been added',
  conceptRemovedFromLoc: 'The concept has been removed from the location',
  whChanged: 'Working hours have been changed',
  avabilityChanged: 'Availability has been changed',
  syncStarted: 'Synchronization has started',
  restaurantOn: 'The restaurant has been turned on',
  restaurantOff: 'The restaurant has been turned off',
  integrationOn: 'Integration has been enabled',
  integrationOff: 'Integration has been disabled',
  locationOn: 'The location has been turned on',
  locationOff: 'The location has been turned off',

  removed: 'The restaurant has been removed',
  added: 'The restaurant has been added',

  restaurantTypes: [
    { text: 'restaurant', value: 'restaurant' },
    { text: 'shop', value: 'shop' },
  ],

  restaurantIsOn: 'Restaurant is on',
  restaurantIsOff: 'Restaurant is off',

  turningOffReasons: {
    'busy': 'busy',
    'hardware_failure': 'hardware failure',
    'internet_problem': 'internet problem',
    'missing_items': 'missing _items',
    'changed_by_marketplace': 'changed by marketplace',
    'holidays': 'holiday',
    'auto': 'changed automatically'
  },

  turningOffDurations: {
    'permanent': 'permanently',
    '1h': '1h',
    '2h': '2h',
    '3h': '3h',
    'endOfDay': 'end of day',
  },

  turningOffHeaders: {
    turnOffDuration: 'turning off duration',
    turnOffReason: 'turning off reason',
  },
}
