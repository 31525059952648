export default {
  rawData: 'Dados do pedido recebidos',
  statusOrders: 'ordens',

  orderCanceled:
    'Seu pedido foi cancelado. Infelizmente, {source} não suporta o cancelamento automático de pedidos, então você precisará cancelar seu pedido pelo call center',
  orderid: 'ID Pedido:',
  phone: 'Numero de telefone:',

  orderCanceledCourier:
    'Seu pedido foi cancelado. Lembre-se de ligar para a empresa de entregas e cancelar o serviço de entrega, caso contrário, serão cobradas taxas de entrega.',

  courierProblem: 'Wystąpił problem z kurierem',
  status: 'estado',
  contactCourier: 'Contactar a empresa de correio para esclarecer a questão',

  clientData: 'Detalhes do encomendante',
  deliveryData: 'Dados de entrega',
  deliveryAddress: 'Endereço de entrega',
  problemWithGettingAddress:
    'Falha em descarregar o endereço completo de entrega. Por favor contacte o cliente por telefone para determinar um endereço de entrega.',
  comments: 'Notas',

  orderDetails: 'Detalhes da encomenda',
  disponsable: 'Adicionar talheres, etc.',
  orderStatus: 'Estado da encomenda',
  orderSource: 'Fonte do pedido',
  paymentMethod: 'Forma de pagamento',
  orderDate: 'Data de encomenda',
  deliveryType: 'Recolha de encomendas',
  payByCash: 'Pagamento na entrega',
  courier: 'Correio',

  statusReady: 'pronto',
  statusDone: 'concluído',

  orderCourierConfirm: 'Tem a certeza de que quer encomendar um estafeta?',

  invoiceData: 'Detalhes das facturas',
  invoiceCompany: 'Nome da empresa',
  invoiceAddress: 'Endereço',
  invoiceTaxID: 'PIN',

  products: 'Produtos encomendados',
  allergies: 'Alergias',
  orderComment: 'Comentário sobre a encomenda',

  cancelBtn: 'cancelar encomenda',

  subtotal: 'Subtotal',
  specialOffers: 'Ofertas Especiais',
  orderValue: 'Valor do contrato',
  deliveryCost: 'Custo de entrega',

  newOrder: 'tem novas encomendas',

  location: 'localização',
  concept: 'conceitos',
  restaurant: 'restaurante',

  statuses: {
    new: 'novo',
    processing: 'aceite',
    'ready_for_pickup': 'pronto para recolha',
    finished: 'concluído',
    'finished_auto': 'concluído automaticamente',
    rejected: 'Rejeitado',
  },

  source: 'agregador',

  sources: {
    Glovo: 'Glovo',
    UberEats: 'UberEats',
    JustEat: 'JustEat',
    Wolt: 'Wolt',
    Bolt: 'Bolt',
    SocialFood: 'SocialFood',
  },

  phones: {
    'delivery_by_ld': '22 185 56 03 / 518 343 803',
    'delivery_by_stava': '777 070 861',

    Bolt: '+48 22 153 00 08',
    Glovo: '22 536 41 60',
  },

  statusChanged: 'O estado da encomenda foi alterado',
  courierOrdered: 'O mensageiro foi encomendado',

  courierStatuses: {
    'pickup_cancelled': 'correio cancelou a recolha da encomenda',
    'pickup_failed': 'a encomenda não pôde ser recolhida',
    'pickup_paused': 'recepção da encomenda suspensa',
    'drop_cancelled': 'o correio cancelou a entrega da encomenda',
    'drop_failed': 'a encomenda não pôde ser entregue',
    'drop_paused': 'a entrega da encomenda foi suspensa',
    'pickup_not_started': 'não começou a recolha',
    'pickup_started': 'arranque da recolha',
    'pickup_on_place': 'pickup no local',
    'pickup_finished': 'recolha terminada',
    'drop_not_started': 'não começou a cair',
    'drop_started': 'gota começou',
    'drop_on_place': 'cair no lugar',
    'drop_finished': 'gota terminada',
  },

  deliveryTypes: {
    door2door: 'reunião à porta',
    outside: 'reunião no exterior',
    'leave@door': 'sair à porta',
  },

  deliveryBy: {
    'pick_up': 'Recolha pessoal',
    'dine_in': 'No local',
    'delivery_by_provider': 'Entrega por ',
    'delivery_by_us': 'Entrega por RebelTang',
    'delivery_by_restaurant': 'Entrega em restaurante',
    'delivery_by_ld': 'Entrega por Lets Deliver',
    'delivery_by_stava': 'Entrega por Stava',
    'delivery_by_stuart': 'Entrega por Stuart',
    'delivery_by_deligoo': 'Entrega por Deligoo',
    'delivery_by_woltdrive': 'Entrega por WoltDrive',
    null: '',
  },

  paymentMethods: {
    paid: 'pago',
    cash: 'dinheiro',
    card: 'cartão',
  },

  rejectionReasons: {
    'missing_items': 'Falta de componentes de encomenda',
    'too_many_orders': 'Demasiadas encomendas',
    'out_of_order': 'Encerramento do restaurante',
    'no_reason': 'Nenhuma razão',
    'canceled_by_marketplace': 'By Client',
    expired: 'Not accepted',
  },

  orderCanceledByMarketplace: 'Your order has been canceled by customer',
  orderExpired: 'The order has been canceled due to exceeding acceptation time',

  orderId: 'Order no',

  minutes: 'minutes',
  statusHeader: 'o correio foi encomendado',
  refreshData: 'Atualizar dados',
  buttonMessage: 'fechar',
};
