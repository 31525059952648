export default {
  statsBy: 'Отримати статистику щодо',
  orders: 'Замовлення',
  locations: 'локації',
  restaurants: 'ресторану',
  menu: 'меню',
  today: 'сьогодні',
  lastWeek: 'минулого тижня',
  lastMonth: 'минулого тижня',
  custom: 'встановити діапазон дат',
  location: 'Локація ',
  restaurant: 'Ресторан',
  menuItem: 'Позиція меню',
  showEmpty: 'Показувати порожні',
  showInternal: 'Показувати внутрішні'
}
