/* eslint-disable @typescript-eslint/camelcase */
export default {
  locations: 'Локації',
  filterCountry: 'фільтрувати країни',
  filterCity: 'фільтрувати міста ',
  filterOptions: 'фільтри',
  removeConfirm: 'Ви впевнені, що хочете видалити вибрану локацію та всі дані?',
  filterClosed: 'ЗАЧИНЕНО',
  filterRegions: 'регіони',

  turnOff: 'Увімкнути локацію',
  turnOn: 'Вимкнути локацію',

  confirmOn: 'Ви впевнені, що хочете увімкнути локацію?',
  confirmOff: 'Ви впевнені, що хочете вимкнути локацію?',

  add: 'Створення нової локації',
  edit: 'Редагування даних локації',

  companyName: 'Назва компанії',
  street: 'Вулиця',
  no: 'Номер',
  city: 'Місто',
  zip: 'Поштовий код',
  contact: 'Контакти',
  comment: 'Коментар',
  guideline: "Інструкція для кур'єра",

  deliveries: 'Редагування даних компаній з доставки',
  deliveryResp: 'Відповідальні за доставку',

  name: 'Назва',

  contactPerson: 'Контактна особа:',

  removed: 'Локацію видалено',
  added: 'Локацію додано',

  countries: {
    pl: 'Польща',
    ua: 'Україна',
    pt: 'Португалія',
    cy: 'Кіпр',
  },

  languages: {
    pl: 'польський',
    ua: 'українська',
    en: 'англійська',
  },

  couriers: {
    delivery_by_provider: 'маркетплейс',
    delivery_by_restaurant: 'ресторан',
    delivery_by_ld: 'Lets Deliver',
    delivery_by_stava: 'Stava',
    delivery_by_stuart: 'Stuart',
    delivery_by_deligoo: 'Deligoo',
  },

  phone: 'Номер телефону',

  addHolidays: 'Add holiday',
  holidayAdded: 'The holiday has been added',
  allowSmsNotifications: 'Дозволити SMS-повідомлення',
  notificationPhone: 'Номер телефону для отримання SMS-повідомлень',
};
