export default {
  add: 'Adding a file',
  edit: 'Edit file',

  name: 'Name',
  file: 'File',

  files: 'Files',
  confirm: 'Are you sure you want to delete the selected file?',

  removed: 'The file has been deleted',
  added: 'The file has been added'
}
