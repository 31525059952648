import { Concept, LocationDeliveryType, SupportedProviders } from '.';

export interface Order {
  id: number;
  conceptId: number;
  items: OrderItem[];
  client: OrderClient;
  delivery?: OrderDelivery;
  status: OrderStatus;
  concept: Concept;
  source: SupportedProviders;
  externalId: string;
  externalId2: string;
  pickupTime: string;
  disposable: boolean;
  value: number;
  deliveryCosts?: number;
  promotions?: number;
  valueAfterPromotions?: number;
  orderSummary?: number;
  deliveryType: OrderDeliveryType;
  allergies?: string;
  invoice?: OrderInvoice;
  deliveryBy: LocationDeliveryType;
  comments?: string;
  createdAt: string;
  paymentMethod: PaymentMethod;
  rejectionReason?: RejectionReasons | AllRejectionReasons;
  statuses?: OrderStatusHistory[];
}

export enum OrderDeliveryType {
  'pick_up' = 'pick_up',
  'dine_in' = 'dine_in',
  'delivery_by_provider' = 'delivery_by_provider',
  'delivery_by_us' = 'delivery_by_us',
}

export interface OrderDelivery {
  id: number;
  courierName?: string;
  courierPhone?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
  notes?: string;
  unit?: string;
  type?: OrderDeliveryPlace;
  externalId?: string;
  externalId2?: string;
  street?: string;
  no?: string;
  zip?: string;
  city?: string;
  status?: OrderDeliveryStatus;
  history?: OrderDeliveryStatusHistory[];
}

export enum OrderDeliveryPlace {
  'door2door' = 'door2door',
  'outside' = 'outside',
  'leave@door' = 'leave@door',
}

export interface OrderInvoice {
  id: number;
  companyName: string;
  address: string;
  taxId: string;
}

export enum OrderDeliveryStatus {
  'pickup_not_started' = 'pickup_not_started',
  'pickup_started' = 'pickup_started',
  'pickup_on_place' = 'pickup_on_place',
  'pickup_finished' = 'pickup_finished',
  'pickup_failed' = 'pickup_failed',
  'pickup_paused' = 'pickup_paused',
  'pickup_cancelled' = 'pickup_cancelled',

  'drop_not_started' = 'drop_not_started',
  'drop_started' = 'drop_started',
  'drop_on_place' = 'drop_on_place',
  'drop_finished' = 'drop_finished',
  'drop_failed' = 'drop_failed',
  'drop_paused' = 'drop_paused',
  'drop_cancelled' = 'drop_cancelled',

  'unknow' = 'unknow',
}

export enum OrderStatus {
  'new' = 'new',
  'processing' = 'processing',
  'finished' = 'finished',
  'rejected' = 'rejected',
  'ready_for_pickup' = 'ready_for_pickup',
  'finished_auto' = 'finished_auto',
}

export enum OrderSource {
  'Glovo' = 'Glovo',
  'UberEats' = 'UberEats',
  'JustEat' = 'JustEat',
  'Wolt' = 'Wolt',
  'Bolt' = 'Bolt',
  'SocialFood' = 'SocialFood'
}

export interface OrderClient {
  firstname: string;
  lastname: string;
  phone?: string;
  phone2?: string;
  email?: string;
  street?: string;
  no?: string;
  zip?: string;
  city?: string;
  externalId?: string;
}

export interface OrderItem {
  id: number;
  itemId: number;
  qua: number;
  value: number;
  mods?: OrderItemMod[];
  comments?: string;
  technicalDescription?: string;
  technicalPhotos?: string;
}

export enum PaymentMethod {
  'paid' = 'paid',
  'cash' = 'cash',
  'card' = 'card',
}

export interface OrderItemMod {
  qua: number;
  value: number;
  mods?: OrderItemMod[];
  category: string;
  name: string;
}

export interface OrderStatusHistory {
  id: number;
  status: OrderStatus;
  createdAt: string;
}

export interface OrderDeliveryStatusHistory {
  id: number;
  status: OrderDeliveryStatus;
  createdAt: string;
}

export interface OrdersFilters {
  status?: OrderStatus[];
  locationId?: number | null;
  conceptsIds?: number[];
  countries?: string[];
  lastId?: number;
  orderId?: string;
  source?: OrderSource[];
}

export interface OrdersStatusNo {
  status: OrderStatus;
  c: number;
}

export interface RawOrder {
  rawOrder: {
    orderId: string;
    source: SupportedProviders;
    data: string;
  };

  parsed: Order;
}

export enum RejectionReasons {
  'missing_items' = 'missing_items',
  'too_many_orders' = 'too_many_orders',
  'out_of_order' = 'out_of_order',
}

export enum AllRejectionReasons {
  'missing_items' = 'missing_items',
  'too_many_orders' = 'too_many_orders',
  'out_of_order' = 'out_of_order',
  'canceled_by_marketplace' = 'canceled_by_marketplace',
  'expired' = 'expired',
}
