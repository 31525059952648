export default {
  add: 'Acrescentar um ficheiro',
  edit: 'Editar ficheiro',

  name: 'Nome',
  file: 'Ficheiro',

  files: 'Ficheiros',
  confirm: 'Tem a certeza de que quer apagar o ficheiro seleccionado?',

  removed: 'O ficheiro foi eliminado',
  added: 'O ficheiro foi adicionado'
}
