import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { AdminStats, Nullable } from '@/interfaces';
import { StatsService } from '@/services';

@Module({ namespaced: true })
export default class extends VuexModule {
  @Action
  public getAdminStats(countries?: string[]): Promise<Nullable<AdminStats>> {
    return StatsService.getAdminStats(countries);
  }
}
