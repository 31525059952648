import { getLocale } from '@/helpers/user';
import { LocationStats, MenuItemStat, Nullable, OrdersFilters, OrderStatus, PlaceStats, RawOrder } from '@/interfaces';
import { OrderModel } from '@/models';
import { OrdersService } from '@/services';
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { ChangeStatusPayload, StatsPayload, GetOrdersPayload, UpdateStatusPayload } from './interfaces';

interface State {
  orders: OrderModel[];
  lastUsedFilters: OrdersFilters | null;
  ordersStats: Record<OrderStatus, number>;
  realTime: boolean;
  ordersRequiredSync: number[];
}

@Module({ namespaced: true })
export default class extends VuexModule<State> {
  orders: OrderModel[] = [];
  lastUsedFilters: OrdersFilters | null = null;
  ordersStats: Record<OrderStatus, number> = {
    new: 0,
    processing: 0,
    finished: 0,
    rejected: 0,
    'ready_for_pickup': 0,
    'finished_auto':0
  };
  realTime = false;
  ordersRequiredSync: number[] = []

  get numberOfOrders(): number {
    return this.ordersStats.new || 0;
  }

  @Action
  async getOrders({ filters, fetchMore }: GetOrdersPayload): Promise<void> {
    !fetchMore && this.context.commit('setOrders', []);

    const _filters = filters || this.lastUsedFilters || {};
    if (fetchMore) {
      _filters.lastId = this.orders[this.orders.length -1].id;
    }

    const newOrders = await OrdersService.findAll(_filters) || [];

    this.context.commit('setOrders', !fetchMore ? newOrders : [ ...this.context.state.orders, ...newOrders ]);
    this.context.commit('setLastUsedFilters', { ...filters });
  }

  @Mutation
  setOrders(orders: OrderModel[]): void {
    this.orders = orders;
  }

  @Mutation
  removeOrderRequiredSync(orderId: number): void {
   const index = this.ordersRequiredSync.indexOf(orderId);
   if (index !== -1) this.ordersRequiredSync.splice(index, 1);
  }

  @Mutation
  setOrdersRequiredSync(orderIds: number | number[]): void {
    if (Array.isArray(orderIds)) {
      this.ordersRequiredSync.push(...orderIds)
    } else {
      this.ordersRequiredSync.push(orderIds)
    }
  }

  @Mutation
  setLastUsedFilters(filters: OrdersFilters): void {
    this.lastUsedFilters = filters;
  }

  @Action({ commit: 'updateOrder' })
  async changeStatus(data: ChangeStatusPayload): Promise<UpdateStatusPayload | null> {
    const updatedFields = await OrdersService.changeStatus(data.orderId, data.status, data.reason, data.time);
    if (updatedFields) {
      this.context.commit('notification/showNotification', 'orders.statusChanged', { root: true });
      return {
        updatedFields,
        orderId: data.orderId,
      };
    }

    return null;
  }

  @Mutation
  updateOrder({ updatedFields, orderId }: UpdateStatusPayload): void {
    const orders = [...this.orders];
    const index = orders.findIndex(order => order.id === orderId);
    const oldStatus = orders[index].status;

    orders[index].status = updatedFields.status;
    orders[index].pickupTime = updatedFields.pickupTime;

    this.orders = orders;

    this.ordersStats[oldStatus]--;
    this.ordersStats[orders[index].status]++;
  }

  @Action
  getTopMenuItems(restaurantId: number): Promise<Nullable<MenuItemStat[]>> {
    return OrdersService.getTopMenuItems(restaurantId);
  }

  @Action
  getLocationsStats({ range, dates, showEmpty, sortDirection, sortKey, showInternal }: StatsPayload): Promise<Nullable<LocationStats[]>> {
    return OrdersService.getLocationsStats(range, dates, showEmpty, sortDirection, sortKey, showInternal);
  }

  @Action
  getRestaurantsStats({ range, dates }: StatsPayload): Promise<Nullable<PlaceStats[]>> {
    return OrdersService.getRestaurantsStats(range, dates);
  }

  @Action
  getMenuStats({ range, dates }: StatsPayload): Promise<Nullable<LocationStats[]>> {
    return OrdersService.getMenuStats(range, dates);
  }

  @Action
  getRawData(id: string): Promise<Nullable<RawOrder[]>> {
    return OrdersService.getRawData(id);
  }

  @Action
  async orderCourier({ orderId }): Promise<void> {
    if (await OrdersService.orderCourier(orderId)) {
      this.context.commit('notification/showNotification', 'orders.courierOrdered', { root: true });
    }
  }

  @Mutation
  toggleRealTime(flag: boolean): void {
    this.realTime = flag;
  }

  @MutationAction({ mutate: ['ordersStats'] })
  async getOrdersStats(): Promise<{ ordersStats: Record<OrderStatus, number> }> {
    const stats = await OrdersService.getOrdersNoByStatus() || [];

    if (!stats) {
      return { ordersStats: {} as Record<OrderStatus, number> };
    }

    const ordersStats = Object.keys(OrderStatus).reduce((all, current) => ({
      ...all, [current]: stats.find(item => item.status === current)?.c || 0
    }), {} as Record<OrderStatus, number>)

    return {
      ordersStats
    }
  }

  @Mutation
  addOrder(order: OrderModel): void {
    let orders = [...this.orders];

    const index = orders.findIndex(item => item.id === order.id);
    if (index !== -1) {
      orders[index] = order;
    } else {
      orders = [
        new OrderModel(order, getLocale()),
        ...(orders.filter(_order => _order.id !== order.id)),
      ]
    }

    this.orders = orders;
    this.ordersStats[order.status]++;
  }

  @Action({ commit: 'addOrder' })
  async getOrderDetails(orderId: number): Promise<OrderModel | null> {
    return OrdersService.findOne(orderId);
  }
}
