import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { LogModel } from '@/models';
import { LogsService } from '@/services';
import { GetLogsPayload, UpdateLogsPayload } from './interfaces';
import { Log } from '@/interfaces';

@Module({ namespaced: true })
export default class extends VuexModule {
  public logs: LogModel[] = [];
  public allLogs = 0;

  @Action({ commit: 'updateLogs' })
  public async getLogs({ filters, fetchMore = false }: GetLogsPayload): Promise<UpdateLogsPayload> {
    const currentLogs = this.logs || [];

    if (fetchMore) {
      filters.lastId = currentLogs[currentLogs.length - 1].id;
    }

    const { items, allItems } = await LogsService.findAll(filters) || { items: [], allItems: 0 };

    return {
      logs: [
        ...(fetchMore ? currentLogs : []),
        ...(items.map(item => new LogModel(item as Partial<Log>)))
      ],
      allLogs: allItems
    }
  }

  @Mutation
  updateLogs({ allLogs, logs }: UpdateLogsPayload): void {
    this.logs = logs;
    this.allLogs = allLogs
  }
}
