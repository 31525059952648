import { Log, LogFiltersOptions, ModelsWithPagination, Nullable } from '@/interfaces';
import apiService from './api';

class LogsService extends apiService{
  findAll(filters: LogFiltersOptions): Promise<Nullable<ModelsWithPagination<Log>>> {
    return this.post('logs', filters || {});
  }
}

export default new LogsService
