export default {
  holidays: 'Holidays',
  dates: 'Dates',
  location: 'Location',

  addHolidays: 'Add holiday',

  added: 'The holiday has been added',
  removed: 'The holiday has been removed',
}
