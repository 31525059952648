export enum LocationDeliveryType {
  'delivery_by_provider' = 'delivery_by_provider',
  'delivery_by_restaurant' = 'delivery_by_restaurant',
  // 'delivery_by_ld' = 'delivery_by_ld',
  'delivery_by_stava' = 'delivery_by_stava',
  // 'delivery_by_ziticity' = 'delivery_by_ziticity',
  'delivery_by_stuart' = 'delivery_by_stuart',
  'delivery_by_deligoo' = 'delivery_by_deligoo',
  'delivery_by_woltdrive' = 'delivery_by_woltdrive',
}

export interface LocationDelivery {
  locationId: number;
  source: string;
  deliveryType: LocationDeliveryType;
}

export interface Location {
  id: number;
  name: string;
  contact: string;
  street: string;
  no: string;
  city: string;
  zip: string;
  comment: string;
  country: string;
  deliveryTypes?: LocationDelivery[];
  phone?: string;
  active?: boolean;
  guideline?: string;
}
