import { MessageBox, MessageBoxColor, NotificationType } from "@/interfaces";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class NotificationStore extends VuexModule {
  type = NotificationType.success;
  message = '';

  messageBoxMessage = '';
  messageBoxColor = MessageBoxColor.blue;

  get getType(): NotificationType {
    return this.type;
  }

  get getMessage(): string {
    return this.message;
  }

  get getMessageBox(): MessageBox {
    return {
      message: this.messageBoxMessage,
      color: this.messageBoxColor
    }
  }

  @Mutation
  showNotification(message: string): void {
    this.message = message;
    this.type = NotificationType.success;
  }

  @Mutation
  showError(message: string): void {
    this.message = message;
    this.type = NotificationType.error;
  }

  @Mutation
  resetNotification(): void {
    this.message = '';
    this.type = NotificationType.success;
  }

  @Mutation
  showMessageBox(data: MessageBox | string): void {
    if (typeof data == 'string') {
      this.messageBoxMessage = data;
    } else {
      this.messageBoxMessage = data.message;
      this.messageBoxColor = data.color;
    }
  }

  @Mutation
  resetMessageBox(): void  {
    this.messageBoxMessage = '';
    this.messageBoxColor = MessageBoxColor.blue;
  }
}
