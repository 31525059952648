import { ChangeOrderType, MenuCategory, MenuItem, Nullable } from '@/interfaces';
import { CopyMenuCategoryPayload } from '@/store/interfaces';
import apiService from './api';
import { MenuModel } from '@/models';

class MenuService extends apiService{
  async findByRestaurant(id: number): Promise<Nullable<MenuModel>> {
    const categories = await this.get<MenuCategory[]>(`menu/${ id }`);
    return categories && new MenuModel(categories);
  }

  addCategory(restaurantId: number, data: Partial<MenuCategory>): Promise<Nullable<MenuCategory>> {
    return this.post(`menu/category/${ restaurantId }`, data);
  }

  removeCategory(id: number): Promise<Nullable<boolean>> {
    return this.delete(`menu/category/${ id }`);
  }

  updateCategory(data: MenuCategory): Promise<Nullable<boolean>> {
    return this.patch(`menu/category/${ data.id }`, data);
  }

  addItem(categoryId: number, data: Partial<MenuItem>): Promise<Nullable<MenuItem>> {
    return this.post('menu/item/' + categoryId, this.dataToFormData(data), this.fileUploadHeaders());
  }

  removeItem(id: number, categoryId: number): Promise<Nullable<boolean>> {
    return this.delete(`menu/item/${ id }/${ categoryId }`);
  }

  updateItem(data: MenuItem): Promise<Nullable<boolean>> {
    return this.patch(`menu/item/${ data.id }`, this.dataToFormData(data), this.fileUploadHeaders());
  }

  addItemsToCategory(categoryId: number, productsIds: number[]): Promise<Nullable<boolean>> {
    return this.post(`menu/addProductsToCategory/${ categoryId }`, { productsIds });
  }

  uploadMenu(restaurantId: number, file: File): Promise<Nullable<boolean>> {
    return this.post('menu/import/' + restaurantId, this.dataToFormData({ file }), this.fileUploadHeaders());
  }

  changeOrder(type: ChangeOrderType, ids: number[], parentId: number | null): Promise<Nullable<boolean>> {
    return this.patch('menu/changeOrder', { type, ids, parentId });
  }

  copyMenuCategory(payload: CopyMenuCategoryPayload): Promise<Nullable<boolean>> {
    return this.post(`menu/copyCategory/${ payload.fromRestaurant }/${ payload.categoryId }`, payload);
  }
}

export default new MenuService
