import apiService from './api';
import { Location, LocationDelivery, Nullable } from '@/interfaces';
import { AddHolidayPayload, QueryLocations } from '@/store/interfaces';
import { buildQuery } from '../helpers/searchParamsBuilder';

class LocationsService extends apiService {
  findAll(filterQuery?: QueryLocations): Promise<Nullable<Location[]>> {
    const url = buildQuery('locations', filterQuery);
    return this.get(url);
  }

  findOne(id: number): Promise<Nullable<Location>> {
    return this.get(`locations/${id}`);
  }

  remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`locations/${id}`);
  }

  update(data: Location): Promise<Nullable<Location>> {
    return this.patch(`locations/${data.id}`, data);
  }

  add(data: Partial<Location>): Promise<Nullable<Location>> {
    return this.post('locations', data);
  }

  async updateDelivery(locationId: number, data: LocationDelivery[]): Promise<Nullable<boolean>> {
    return this.patch(`locations/updateDelivery/${locationId}`, data);
  }

  getCountries(): Promise<Nullable<string[]>> {
    return this.get('locations/getcountries');
  }
}

export default new LocationsService();
