import { FileType, File, Nullable } from '@/interfaces';
import apiService from './api';

class FilesService extends apiService{
  findByParentId(parentId: number, type: FileType): Promise<Nullable<File[]>> {
    return this.get(`files/${ type }/${ parentId }`);
  }

  async remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`files/${ id }`);
  }

  update(data: File): Promise<Nullable<File>> {
    return this.patch(`files/${ data.id }`, this.dataToFormData(data), this.fileUploadHeaders());
  }

  add(parentId: number, type: FileType, data: Partial<File>): Promise<Nullable<File>> {
    return this.post(`files/${ type }/${ parentId }`, this.dataToFormData(data), this.fileUploadHeaders());
  }
}

export default new FilesService
