import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import * as Sentry from '@sentry/vue';
import VueI18n from 'vue-i18n';

import router from './router';
import store from './store';
import './registerServiceWorker';
import langs from './langs';
import { getLocale } from './helpers/user';

Vue.config.productionTip = false;

// Sentry.init({
//   Vue: Vue,
//   dsn: "https://797b92be723b4144b352783a1441dc27@int.sentry.rebeltang.com/4",
//   autoSessionTracking: true,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   environment: process.env.NODE_ENV,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: getLocale(),
  messages: langs,
});

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
