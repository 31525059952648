
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { MessageBox } from '@/interfaces';

  const notificationStore = namespace('notification');

  @Component
  export default class MessageModal extends Vue {
    @notificationStore.Getter('getMessageBox')
    readonly messageBoxData!: MessageBox;

    get message(): string {
      return (this.messageBoxData && this.messageBoxData.message) || '';
    }

    get color(): string {
      return this.messageBoxData && this.messageBoxData.color;
    }

    closeModal(): void {
      this.$emit('close');
    }
  }
