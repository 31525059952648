import {
  AllStoreStatuses,
  Concept,
  ConceptAvailability,
  Location,
  MenuCategory,
  Nullable,
  ProuductsAvailability,
  Restaurant,
  WorkingHour,
} from '@/interfaces';
import { ConceptModel } from '@/models';
import { ToggleMenuItemsPayload } from '@/store/interfaces';
import apiService from './api';
import { MenuModel } from '@/models/menu.model';
import { buildQuery } from '@/helpers/searchParamsBuilder';

class ConceptsService extends apiService {
  async findByLocation(locationId: number): Promise<Nullable<ConceptModel[]>> {
    const concepts = await this.get<Concept[]>(`concepts/${locationId}`);
    return concepts ? this.mapAndSortConcepts(concepts) : null;
  }

  remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`concepts/${id}`);
  }

  update(data: Concept): Promise<Nullable<Concept>> {
    return this.patch(`concepts/${data.id}`, data);
  }

  add(locationId: number, data: Partial<Concept>): Promise<Nullable<Concept>> {
    return this.post(`concepts/${locationId}`, data);
  }

  getConceptsToAdd(location: Location): Promise<Nullable<Partial<Restaurant>[]>> {
    return this.get(`concepts/toadd/${location.id}/${location.country}`);
  }

  getLocations(restauratnId: number): Promise<Nullable<Location[]>> {
    return this.get(`concepts/locations/${restauratnId}`);
  }

  async getConceptsForUser(): Promise<Nullable<ConceptModel[]>> {
    const concepts = await this.get<Concept[]>('concepts/user');
    return concepts ? this.mapAndSortConcepts(concepts) : null;
  }

  findOne(id: number): Promise<Nullable<Concept & { workingHours: WorkingHour[] }>> {
    return this.get(`concepts/find/${id}`);
  }

  async updateHour(conceptId: number, data: WorkingHour[]): Promise<Nullable<boolean>> {
    return this.patch(`concepts/updatehours/${conceptId}`, data);
  }

  async updateHoursInLocation(locationId: number, data: WorkingHour[]): Promise<Nullable<boolean>> {
    return this.patch(`concepts/updatehoursinlocation/${locationId}`, data);
  }

  async getConceptMenu(id: number): Promise<Nullable<MenuModel>> {
    const categories = await this.get<MenuCategory[]>(`concepts/menu/${id}`);
    return categories && new MenuModel(categories);
  }

  async toggleMenuItems(
    conceptId: number,
    data: Omit<ToggleMenuItemsPayload, 'conceptId'>
  ): Promise<Nullable<boolean>> {
    return this.patch(`concepts/toggle/${conceptId}`, data);
  }

  async getConceptsAvailability(date?: string, conceptId?: string): Promise<ConceptAvailability[]> {
    const queryParams = {};
    if (date) {
      queryParams['date'] = date;
    }
    if (conceptId) {
      queryParams['conceptId'] = conceptId;
    }

    const url = buildQuery('concepts/availability/concepts', queryParams);
    return (await this.get(url)) || [];
  }

  async getUnavailableProducts(
    date?: string,
    conceptId?: string
  ): Promise<ProuductsAvailability[]> {
    const queryParams = {};
    if (date) {
      queryParams['date'] = date;
    }
    if (conceptId) {
      queryParams['conceptId'] = conceptId;
    }

    const url = buildQuery('concepts/availability/products', queryParams);
    return (await this.get(url)) || [];
  }

  allRestaurantsStatus(): Promise<Nullable<AllStoreStatuses>> {
    return this.get('concepts/allRestaurantsStatus');
  }

  private mapAndSortConcepts(concepts: Concept[]): ConceptModel[] {
    if (!concepts) {
      return [];
    }

    return concepts
      .map((concept: Concept) => new ConceptModel(concept))
      .sort((a: ConceptModel, b: ConceptModel) => (a.name > b.name ? 1 : -1));
  }
}

export default new ConceptsService();
