export default {
  duplicatedEmail: 'O endereço de correio electrónico especificado já existe no sistema',
  nameDuplicated: 'O nome dado já existe no sistema',
  wrongStatus: 'O estatuto dado é incorrecto',
  wrongRejectionReason: 'A razão dada para a rejeição é incorrecta',
  duplicatedMenuItem: 'O produto especificado já foi adicionado à categoria seleccionada',
  cannotRemoveCurrentMenu: 'O menu actual não pode ser apagado porque faz parte das encomendas existentes',
  partnerIDinUse: 'O ID de parceiro que introduziu já está a ser utilizado.',
  'Status not changed': 'Order status has not been changed. Please restart application and try again.',
  'Order is already rejected': 'Order status cannot be updated, because the order was already rejected.',
}
