export interface TurningOffReasonsResult {
  value: boolean;
  reason: TurningOffReason;
  duration: TurningOffDuration;
}

export const turningOffReasonsValues = ['busy', 'hardware_failure', 'internet_problem', 'missing_items'] as const;

export type TurningOffReason = typeof turningOffReasonsValues[number];

export type AllTurningOffReasons = TurningOffReason | 'changed_by_marketplace' | 'holidays' | 'auto';

export type TurningOffDuration = 'permanent' | '1h' | '2h' | '3h';
