export default {
  add: 'Додавання файлу',
  edit: 'Редагувати файл',

  name: 'Назва',
  file: 'Файл',

  files: 'Файли',
  confirm: 'Ви впевнені, що хочете видалити вибраний файл?',

  removed: 'Файл видалено',
  added: 'Файл додано'
}
