export default {
  rawData: 'Отримані дані замовлення',
  statusOrders: 'замовлення',

  orderCanceled:
    'Ваше замовлення було скасовано. На жаль, {source} не підтримує автоматичне скасування замовлень, тому вам доведеться скасувати своє замовлення через Call Center',
  orderid: 'ID замовлення:',
  phone: 'номер телефону:',

  orderCanceledCourier:
    "Ваше замовлення було скасовано.Не забудьте звернутись в кур'єрську компанію та скасувати замовлення,інакше стягнеться плата за доставку",

  courierProblem: "Виникла проблема з кур'єром",
  status: 'статус',
  contactCourier: "Зверніться до кур'єрської компанії, щоб уточнити це питання",

  clientData: 'Деталі замовлення',
  deliveryData: 'Дані доставки ',
  deliveryAddress: 'Адреса доставки',
  problemWithGettingAddress:
    "Не вдалося завантажити повну адресу доставки.Будь ласка, зв'яжіться з клієнтом по телефону,щоб уточнити адресу доставки.",
  comments: 'Зауваження',

  predictedPickupTime: 'Орієнтовний час доставки',

  orderDetails: 'Деталі замовлення',
  disponsable: 'Додати столові прилади',
  orderStatus: 'Статус замовлення',
  orderSource: 'Джерело замовлення',
  paymentMethod: 'Метод оплати',
  orderDate: 'Дата замовлення',
  deliveryType: 'Отримання замовлення',
  payByCash: 'Оплата при доставці',
  courier: "Кур'єр",

  statusReady: 'готове',
  statusDone: 'завершене',

  orderCourierConfirm: "Ви впевнені, що хочете замовити кур'єра?",

  invoiceData: 'Деталі на рахунок',
  invoiceCompany: 'Назва компанії',
  invoiceAddress: 'Адреса',
  invoiceTaxID: 'Код ІПН / ЄДРПОУ',

  products: 'Замовлені продукти',
  allergies: 'Алегрія',
  orderComment: 'Коментар до замовлення',

  cancelBtn: 'скасувати замовлення',

  subtotal: 'Вартість',
  specialOffers: 'Спеціальні пропозиції',
  orderValue: 'Сума замовлення ',
  deliveryCost: 'Вартість доставки',

  newOrder: 'у Вас нове замовлення',

  location: 'локація',
  concept: 'концепти',
  restaurant: 'ресторан',

  statuses: {
    new: 'нове',
    processing: 'прийняте',
    'ready_for_pickup': "готове до передачі кур'єрові",
    finished: 'завершене',
    'finished_auto': 'завершене автоматично',
    rejected: 'відхилене',
  },

  source: 'агрегатор',

  sources: {
    Glovo: 'Glovo',
    UberEats: 'UberEats',
    JustEat: 'JustEat',
    Wolt: 'Wolt',
    Bolt: 'Bolt',
    SocialFood: 'SocialFood',
  },

  phones: {
    'delivery_by_ld': '22 185 56 03 / 518 343 803',
    'delivery_by_stava': '777 070 861',

    Bolt: '+48 22 153 00 08',
    Glovo: '22 536 41 60',
  },

  statusChanged: 'Статус замовлення змінено',
  courierOrdered: 'Кур`єр прийняв замовлення',

  courierStatuses: {
    'pickup_cancelled': 'кур`єр скасував передачу замовлення',
    'pickup_failed': 'замовлення не вдалося',
    'pickup_paused': 'прийом замовлення призупинено',
    'drop_cancelled': "кур'єр скасував доставку замовлення",
    'drop_failed': 'замовлення не вдалося доставити',
    'drop_paused': 'доставку замовлення призупинено',
    'pickup_not_started': 'передача не розпочалась',
    'pickup_started': 'розпочалась передача замовлення',
    'pickup_on_place': 'кур`єр приїхав на вказану адресу',
    'pickup_finished': 'передачу завершено',
    'drop_not_started': 'доставка не розпочалась',
    'drop_started': 'доставка розпочалась',
    'drop_on_place': 'доставка за місцем призначення',
    'drop_finished': 'доставка завершена',
  },

  deliveryTypes: {
    door2door: 'зустріч біля дверей',
    outside: 'зустріч на вулиці',
    'leave@door': 'залишити біля дверей',
  },

  deliveryBy: {
    'pick_up': 'Самовиніс',
    'dine_in': 'На місці',
    'delivery_by_provider': 'Доставка ',
    'delivery_by_us': 'Доставка RebelTang',
    'delivery_by_restaurant': 'Доставка ресторану',
    'delivery_by_ld': 'Доставка Lets Deliver',
    'delivery_by_stava': 'Доставка Stava',
    'delivery_by_stuart': 'Доставка Stuart',
    'delivery_by_deligoo': 'Доставка Deligoo',
    'delivery_by_woltdrive': 'Доставка WoltDrive',
    null: '',
  },

  paymentMethods: {
    paid: 'оплачено',
    cash: 'готівка',
    card: 'карта',
  },

  rejectionReasons: {
    'missing_items': 'Брак складників замовлення',
    'too_many_orders': 'Забагато замовлень',
    'out_of_order': 'Закриття ресторану',
    'no_reason': 'Немає причини',
    expired: 'Not accepted',
    'canceled_by_marketplace': 'змінено клієнтом',
  },

  orderCanceledByMarketplace: 'Клієнт скасував замовлення',
  orderExpired: 'Скасовано через перевищення часу прийняття замовлення',

  orderId: 'Номер замовлення',

  minutes: 'хвилин',
  statusHeader: "кур'єра замовлено",
  refreshData: 'Оновити дані',
  buttonMessage: 'близько',
};
