export default {
  removeConfirm: 'Tem a certeza de que quer eliminar o utilizador seleccionado?',

  edit: 'Edição',
  add: 'Acrescentando',
  user: 'utilizador',
  admin: 'administrador',

  firstname: 'Imię',
  lastname: 'Nazwisko',
  email: 'Correio electrónico',
  role: 'Role',

  users: 'Utilizadores',

  removed: 'O utilizador foi eliminado',
  updated: 'O utilizador foi actualizado',
  added: 'O utilizador foi adicionado',

  limitations: 'Limit access to',

  roles: {
    admin: 'admin',
    country: 'country manager',
    regional: 'regional manager',
    brand: 'brand manager',
  },
}
