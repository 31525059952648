import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { LocationsService } from '@/services';
import { Location, Nullable } from '@/interfaces';
import { AddHolidayPayload, QueryLocations, UpdateDeliveryPayload } from './interfaces';
import { LocationModel } from '@/models';

@Module({ namespaced: true })
export default class extends VuexModule {
  locations: Location[] = [];
  countries: string[] = [];

  @MutationAction({ mutate: ['locations']})
  async getLocations(filterQuery?: QueryLocations): Promise<{ locations: Nullable<Location[]> }> {
    return {
      locations: await LocationsService.findAll(filterQuery)
    };
  }

  @Action({ commit: 'updateLocationMutation'})
  async updateLocation(data: Location): Promise<Location> {
    await LocationsService.update(data);
    return data;
  }

  @Action({ commit: 'removeLocationMutation' })
  async removeLocation(id: number): Promise<number | null> {
    if (await LocationsService.remove(id)) {
      this.context.commit('notification/showNotification', 'locations.removed', { root: true });
      
      return id;
    }

    return null;
  }

  @Action({ commit: 'addLocationMutation' })
  async addLocation(data: Partial<Location>): Promise<Nullable<Location>> {
    const item = await LocationsService.add(data);
    if (item) {
      this.context.commit('notification/showNotification', 'locations.added', { root: true });
      
      return item;
    }

    return null;
  }

  @Action
  async getLocation(id: number): Promise<Nullable<Location>> {
    const location = await LocationsService.findOne(id);
    return location ? new LocationModel(location) : null;
  }

  @Action
  updateDelivery({ locationId, data }: UpdateDeliveryPayload): void {
    LocationsService.updateDelivery(locationId, data);
  }

  @Mutation
  removeLocationMutation(id: number): void {
    this.locations = this.locations.filter(item => item.id !== id);
  }

  @Mutation
  updateLocationMutation(item: Location): void {
    const locations = [ ...this.locations ];
    const index = locations.findIndex(_item => _item.id === item.id);
    locations[index] = {...locations[index], ...item};
    this.locations = locations;
  }

  @Mutation
  addLocationMutation(item: Location): void {
    this.locations.push(item);
  }

  @MutationAction({ mutate: ['countries']})
  async getCountries(): Promise<{ countries: Nullable<string[]> }> {
    return {
      countries: await LocationsService.getCountries()
    };
  }
}
