export enum SupportedCountry {
  pl = 'pl',
  ua = 'ua',
  pt = 'pt',
  cy = 'cy',
}

export enum SupportedLanguages {
  pl = 'pl',
  ua = 'ua',
  en = 'en',
}
