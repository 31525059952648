import { AdminStats, Nullable } from '@/interfaces';
import apiService from './api';

class StatsService extends apiService{
  getAdminStats(countries?: string[]): Promise<Nullable<AdminStats>> {
    return this.post('stats', { countries });
  }
}

export default new StatsService
