import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import {
  AddConceptPayload,
  ChangeConceptStatusPayload,
  ChangeStatusLocationPayload,
  GetRestaurantStatusResponse,
  SynchronizeAllConceptsPayload,
  SynchronizeDataPayload,
  SynchronizeLocationPayload,
  ToggleMenuItemsPayload,
  UpdateHoursInLocationPayload,
  UpdateHoursPayload,
} from './interfaces';
import {
  Concept,
  Location,
  MenuCategory,
  Nullable,
  Restaurant,
  SupportedProviders,
} from '@/interfaces';
import { ConceptsService, ProvidersService } from '@/services';
import { ConceptModel, MenuCategoryModel } from '@/models';
import { MenuModel } from '@/models/menu.model';
import { RestaurantStatusQueue } from '@/services/providers';

@Module({ namespaced: true })
export default class extends VuexModule {
  concepts: ConceptModel[] = [];
  conceptsForUser: ConceptModel[] = [];

  @MutationAction({ mutate: ['concepts'] })
  public async getConcepts(locationId: number): Promise<{ concepts: Nullable<ConceptModel[]> }> {
    const currentLocationId = this.concepts[0]?.locationId;

    const concepts =
      currentLocationId === locationId
        ? this.concepts
        : await ConceptsService.findByLocation(locationId);

    return { concepts };
  }

  @Action({ commit: 'removeConceptMutation' })
  async removeConcept(id: number): Promise<number | null> {
    if (await ConceptsService.remove(id)) {
      this.context.commit('notification/showNotification', 'restaurants.conceptRemovedFromLoc', {
        root: true,
      });

      return id;
    }

    return null;
  }

  @Mutation
  removeConceptMutation(id: number): void {
    this.concepts = this.concepts.filter((item) => item.id !== id);
  }

  @Action({ commit: 'updateConceptMutation' })
  async updateConcept(data: Concept): Promise<Nullable<Concept>> {
    return ConceptsService.update(data);
  }

  @Mutation
  updateConceptMutation(item: Concept): void {
    const concepts = [...this.concepts];
    const index = concepts.findIndex((_item) => _item.id === item.id);
    concepts[index] = new ConceptModel({ ...concepts[index], ...item });
    this.concepts = concepts;
  }

  @Action({ commit: 'addConceptMutation' })
  async addConcept({ locationId, data }: AddConceptPayload): Promise<Concept | null> {
    const item = await ConceptsService.add(locationId, data);
    if (item) {
      this.context.commit('notification/showNotification', 'restaurants.conceptAdded', {
        root: true,
      });

      return item;
    }

    return null;
  }

  @Mutation
  addConceptMutation(item: Concept): void {
    const concept = {
      ...item,
      name: item?.restaurant?.name || '',
    };
    this.concepts.push(new ConceptModel(concept));
  }

  @Action
  getConcept(id: number): Promise<Nullable<Concept>> {
    return ConceptsService.findOne(id);
  }

  @Action
  getConceptsToAdd(location: Location): Promise<Nullable<Partial<Restaurant>[]>> {
    return ConceptsService.getConceptsToAdd(location);
  }

  @Action
  getLocations(restauratnId: number): Promise<Nullable<Location[]>> {
    return ConceptsService.getLocations(restauratnId);
  }

  @MutationAction({ mutate: ['conceptsForUser'] })
  async getForUser(
    withStoreStatuses = false
  ): Promise<{ conceptsForUser: Nullable<ConceptModel[]> }> {
    const conceptsForUser = await ConceptsService.getConceptsForUser();

    if (conceptsForUser && withStoreStatuses) {
      const statuses = await ProvidersService.getRestaurantStatusForUser();

      if (statuses) {
        for (const concept of conceptsForUser) {
          concept.setStoreStatus(statuses[concept.id]);
        }
      }
    }

    return {
      conceptsForUser,
    };
  }

  @Action({ commit: 'updateHoursMutation' })
  async updateHours({ conceptId, data }: UpdateHoursPayload): Promise<UpdateHoursPayload | null> {
    if (await ConceptsService.updateHour(conceptId, data)) {
      this.context.commit('notification/showNotification', 'restaurants.whChanged', { root: true });
      return { conceptId, data };
    }

    return null;
  }

  @Mutation
  updateHoursMutation({ conceptId, data }: UpdateHoursPayload): void {
    const itemNdx = this.conceptsForUser.findIndex((item) => item.id === conceptId);
    if (itemNdx !== -1) {
      this.conceptsForUser[itemNdx].whs = data;
    }
  }

  @Action
  async updateHoursInLocation({ locationId, data }: UpdateHoursInLocationPayload): Promise<void> {
    if (await ConceptsService.updateHoursInLocation(locationId, data)) {
      this.context.commit('notification/showNotification', 'restaurants.whChanged', { root: true });
    }
  }

  @Action
  getConceptMenu(id: number): Promise<Nullable<MenuModel>> {
    return ConceptsService.getConceptMenu(id);
  }

  @Action
  async toggleMenuItems({ conceptId, ...data }: ToggleMenuItemsPayload): Promise<boolean> {
    if (await ConceptsService.toggleMenuItems(conceptId, data)) {
      this.context.commit('notification/showNotification', 'restaurants.avabilityChanged', {
        root: true,
      });
      return true;
    } else {
      return false;
    }
  }

  @Action
  async synchronizeData({
    conceptId,
    provider,
    scheduledTime,
  }: SynchronizeDataPayload): Promise<void> {
    if (await ProvidersService.synchronizeData(conceptId, provider, scheduledTime)) {
      this.context.commit('notification/showNotification', 'restaurants.syncStarted', {
        root: true,
      });
    }
  }

  @Action
  async synchronizeAllConcepts({
    restaurantId,
    provider,
    scheduledTime,
  }: SynchronizeAllConceptsPayload): Promise<void> {
    if (await ProvidersService.synchronizeAllConcepts(restaurantId, provider, scheduledTime)) {
      this.context.commit('notification/showNotification', 'restaurants.syncStarted', {
        root: true,
      });
    }
  }

  @Action
  async synchronizeLocation({
    locationId,
    provider,
    scheduledTime,
  }: SynchronizeLocationPayload): Promise<void> {
    if (await ProvidersService.synchronizeLocation(locationId, provider, scheduledTime)) {
      this.context.commit('notification/showNotification', 'restaurants.syncStarted', {
        root: true,
      });
    }
  }

  @Action({ commit: 'updateRestaurantStatus' })
  async changeStatus({
    id,
    ...data
  }: ChangeConceptStatusPayload): Promise<ChangeConceptStatusPayload | null> {
    if (await ProvidersService.changeStatus(id, data)) {
      this.context.commit(
        'notification/showNotification',
        `restaurants.${data.status ? 'restaurantOn' : 'restaurantOff'}`,
        { root: true }
      );

      return { id, ...data };
    }

    return null;
  }

  @Mutation
  updateRestaurantStatus({
    id,
    status,
    provider,
    reason,
    duration,
  }: ChangeConceptStatusPayload): void {
    const toUpdate = ['conceptsForUser', 'concepts'];

    toUpdate.forEach((field) => {
      const concept: ConceptModel = this[field].find((item: Concept) => item.id === id);

      if (!concept) {
        return;
      }

      let until = '';
      if (duration && duration !== 'permanent') {
        const date = new Date();
        date.setHours(date.getHours() + parseInt(duration));
        until = date.toISOString();
      }

      const statuses = { ...(concept?.storeStatus || {}) };
      const newStatus = { status, reason, until, createdAt: '', updatedAt: '' };

      if (provider) {
        statuses[provider] = newStatus;
      } else {
        Object.keys(statuses).forEach((provider) => (statuses[provider] = newStatus));
      }

      concept.setStoreStatus(statuses);
    });
  }

  @Action
  async changePosStatus({ id, status, provider }: ChangeConceptStatusPayload): Promise<void> {
    if (await ProvidersService.changePosStatus(id, status, provider)) {
      this.context.commit(
        'notification/showNotification',
        `restaurants.${status ? 'integrationOn' : 'integrationOff'}`,
        { root: true }
      );
    }
  }

  @Action({ commit: 'updateRestaurantStatuses' })
  async getRestaurantStatus(conceptId: number): Promise<GetRestaurantStatusResponse> {
    const statuses = await ProvidersService.getRestaurantStatus(conceptId);
    return { conceptId, statuses: statuses?.[conceptId] };
  }

  @Action
  async getRestaurantQueueStatus(conceptId: number): Promise<Nullable<RestaurantStatusQueue>> {
    return await ProvidersService.getRestaurantStatusQueue(conceptId);
  }

  @Mutation
  updateRestaurantStatuses({ conceptId, statuses }: GetRestaurantStatusResponse): void {
    const toUpdate = ['conceptsForUser', 'concepts'];

    toUpdate.forEach((field) => {
      const itemNdx = this[field].findIndex((item: Concept) => item.id === conceptId);

      if (itemNdx !== -1) {
        (this[field][itemNdx] as ConceptModel).setStoreStatus(statuses);
      }
    });
  }

  @Action
  getPosStatus(conceptId: number): Promise<Nullable<Record<SupportedProviders, boolean>>> {
    return ProvidersService.getPosStatus(conceptId);
  }

  @Action({ commit: 'updateAllRestaurantStatuses' })
  async changeStatusLocation({
    locationId,
    ...data
  }: ChangeStatusLocationPayload): Promise<boolean | undefined> {
    if (await ProvidersService.changeStatusLocation(locationId, data)) {
      this.context.commit(
        'notification/showNotification',
        `restaurants.${data.status ? 'locationOn' : 'locationOff'}`,
        { root: true }
      );

      return data.status;
    }
  }

  @Mutation
  updateAllRestaurantStatuses(status: boolean): void {
    const concepts = [...this.concepts];

    concepts.forEach((c) => {
      if (!c.storeStatus) {
        return c;
      }

      c.storeStatus = Object.keys(c.storeStatus).reduce((all, item) => {
        return {
          ...all,
          [item]: {
            status,
            reason: c.storeStatus?.[item].reason,
          },
        };
      }, {});
    });

    this.concepts = concepts;
  }
}
