export default {
  add: 'Dodawanie kategorii menu',
  edit: 'Edycja kategorii menu',

  techPhotosLabel: 'Prześlij zdjęcia techniczne (max 3)',
  techDescLabel: 'Opis techniczny',

  name: 'Nazwa',
  type: 'Typ',
  limits: 'Ograniczenia ilości',
  from: 'od',
  to: 'do',
  reset: 'zeruj ceny produktów',

  productsCat: 'kategoria produktów',
  modsCat: 'kategoria modyfikatorów',

  addItem: 'Tworzenie pozycji menu',
  editItem: 'Edycja pozycji menu',
  choose: 'Wybierz istniejące produkty',
  addNew: 'lub dodaj nowy',
  products: 'Produkty',

  photo: 'Zdjęcie',
  desc: 'Opis',
  qua: 'Gramatura/ilość',
  price: 'Cena',
  mods: 'Modyfikatory',

  menu: 'Menu',
  confirmRemove: 'Czy na pewno chcesz usunąć wybraną pozycje?',
  confirmRemoveCategory:
    'Czy na pewno chcesz usunąć wybraną kategorię oraz wszystkie pozycje, które zawiera?',

  importCsv: 'Wybierz plik CSV z menu do zaimportowania',
  confirmImport:
    'Zaimportowanie nowego menu spowoduje usunięcie obecnego menu (wraz ze zdjęciami i modyfikatorami). Czy na pewno chcesz kontynuować?',
  chhoseCSV: 'Proszę wybrać plik CSV',

  accessibility: 'Dostępność menu',

  top: 'Top dań',

  catRemoved: 'Kategoria została usunięta',
  catAdded: 'Kategoria została dodana',
  itemRemoved: 'Pozycja menu została usunięta',
  itemAdded: 'Pozycja menu została dodana',
  itemAddedToCat: 'Produkty zostały dodane do kategorii',
  menuImported: 'Menu zostało zaimportowane',

  copyCategory: 'Kopiowanie kategorii menu',
  categoryCopied: 'Kategoria została skopiowana',
  categoryCopiedError: 'Nie udało się skopiować kategorii',
  copyWithMods: 'Kopiuj wraz z modyfikatorami',

  excludeFromSync: 'wyklucz z synchronizacji',
  requiredMenuItem: 'wymagana pozycja',

  turnOnSelected: 'włącz zaznaczone',
  turnOffSelected: 'wyłącz zaznaczone',
  turnOffPermanent: 'na stałe',
  turnOffToday: 'do końca dnia',

  itemTypes: {
    item: 'produkt',
    set: 'zestaw',
  },
  components: 'Elementy składowe',
};
