import { OrderDeliveryStatusHistory, OrderDeliveryStatus } from "@/interfaces";
import { toLocale } from '@/helpers/date';

export class OrderDeliveryStatusHistoryModel implements OrderDeliveryStatusHistory {
  id!: number;
  status!: OrderDeliveryStatus;
  createdAt!: string;

  public constructor(data: Partial<OrderDeliveryStatusHistory>) {
    Object.assign(this, data);

    this.createdAt = toLocale(this.createdAt);
  }

  public getStatusName(): string {
    return this.status;
  }
}
