
  import { Component, Mixins } from 'vue-property-decorator';
  import beepOnOpen from '@/mixins/beepOnOpen';

  @Component
  export default class OfflinesModal extends Mixins(beepOnOpen) {
    closeModal(): void {
      this.$emit('close');
    }
  }
