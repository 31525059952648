import { ChangeItemsAvailability, MenuCategory, MenuCategoryType, MenuItem, MenuItemType } from '@/interfaces';
import { MenuCategoryModel, MenuItemModel } from '.';
import { sortByName } from '@/helpers/sort';

export class MenuModel {
  private categories: MenuCategoryModel[] = [];

  constructor(categories: MenuCategory[]) {
    this.categories = categories.map(category => new MenuCategoryModel(category));
  }

  getCategories(): MenuCategoryModel[] {
    return this.categories;
  }

  private get allItems(): MenuItemModel[] {
    return this.categories.flatMap(category => category.items);
  }

  updateItemsAvailability(itemsId: number[], availability: ChangeItemsAvailability): number[] {
    this.changeItemsAvailability(itemsId, availability);

    let relatedSets: MenuItemModel[] = this.findSetsWithComponents(itemsId);

    if (availability.state) {
      relatedSets = relatedSets.filter(set => {
        return set.getComponents()
          .map(componentId => this.productsAvailability[componentId])
          .every(isActive => isActive);
      });
    }

    const relatedSetsId = relatedSets.map(item => item.id);

    this.changeItemsAvailability(relatedSetsId, availability);

    return [
      ...itemsId,
      ...relatedSetsId
    ]
  }

  private findSetsWithComponents(itemsId: number[]): MenuItemModel[] {
    return itemsId.flatMap(itemId =>
      this.allItems.filter(item => item?.getComponents()?.includes(itemId)) || []
    );
  }

  private changeItemsAvailability(itemsId: number[], availability: ChangeItemsAvailability): void {
    this.categories = this.categories.map(category => new MenuCategoryModel({
      ...category,
      items: category.items.map(item => {
        const needUpdate = itemsId.includes(item.id);

        return {
          ...item,
          active: needUpdate ? availability.state : item.active,
          until: needUpdate ? availability.until : item.until,
        }
      })
    }));
  }

  get productsAvailability(): Record<number, boolean> {
    return this.categories?.flatMap(category => category.items)
      .reduce((all, curr) => ({
        ...all,
        [curr.id]: !!curr.active,
      }), {});
  }

  getAllProducts(): MenuItemModel[] {
    return this.getCategories()
      .filter(category => category.type === MenuCategoryType.products && category.items && category.items.length)
      .map(item => item.items)
      .reduce((result, item) => ([...result, ...item]), [])
      .sort((a, b) => sortByName(a.name, b.name));
  }

  getAllComponents(): MenuItem[] {
    return this.getAllProducts().filter(item => item.type === MenuItemType.item);
  }

  getModifiers(): MenuCategory[] {
    return this.getCategories()
      .filter(category => category.type === MenuCategoryType.modifiers);
  }
}
