import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { FilesService } from '@/services';
import { File, Nullable } from '@/interfaces'
import { AddFilePayload, FindByParentIdPayload } from './interfaces';

@Module({ namespaced: true })
export default class extends VuexModule {
  files: File[] = [];

  get getFiles(): File[] {
    return this.files;
  }

  @MutationAction( { mutate: ['files'] } )
  public async findByParentId({ parentId, type }: FindByParentIdPayload): Promise<{ files: Nullable<File[]> }> {
    return {
      files: await FilesService.findByParentId(parentId, type)
    }
  }

  @Action({ commit: 'removeFileMutation' })
  public async removeFile(id: number): Promise<Nullable<number>> {
    if (await FilesService.remove(id)) {
      this.context.commit('notification/showNotification', 'files.removed', { root: true });
      return id;
    }

    return null;
  }

  @Mutation
  removeFileMutation(id: number): void {
    this.files = this.files.filter(item => item.id !== id);
  }

  @Action({ commit: 'updateFileMutation' })
  updateFile(data: File): Promise<Nullable<File>> {
    return FilesService.update(data);
  }

  @Mutation
  updateFileMutation(item: File): void {
    const files = [ ...this.files ];
    const index = files.findIndex(_item => _item.id === item.id);
    files[index] = item;
    this.files = files;
  }

  @Action({ commit: 'addFileMutation' })
  public async addFile({ parentId, data, type }: AddFilePayload): Promise<Nullable<File>> {
    const item = await FilesService.add(parentId, type, data);
    if (item) {
      this.context.commit('notification/showNotification', 'files.added', { root: true });
      return item;
    }

    return null;
  }

  @Mutation
  addFileMutation(item: File): void {
    const files = [ ...this.files ];
    files.push(item);
    this.files = files.sort((a, b) => a.name.localeCompare(b.name));
  }
}
