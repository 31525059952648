export default {
  removeConfirm: 'Czy na pewno chcesz usunąć wybranego użytkownika?',

  edit: 'Edycja',
  add: 'Dodawanie',
  user: 'użytkownika',
  admin: 'administratora',

  firstname: 'Imię',
  lastname: 'Nazwisko',
  email: 'E-mail',
  role: 'Rola',

  users: 'Użytkownicy',

  removed: 'Użytkownik został usunięty',
  updated: 'Użytkownik został zaktualizowany',
  added: 'Użytkownik został dodany',

  limitations: 'Ogranicz dostęp do',

  roles: {
    admin: 'administrator',
    country: 'country manager',
    regional: 'regional manager',
    brand: 'brand manager',
  },
}
