import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { io } from 'socket.io-client';

import { AuthService, ProvidersService } from '@/services';
import { getLang, isLogged } from '@/helpers/user';
import router from '@/router';
import { JWToken, Nullable, Optional, Role, SupportedProviders } from '@/interfaces';

import { LoginPayload, SetNewPasswordPayload, Socket } from './interfaces';
import { decodeJWT } from '@/helpers/jwt';

@Module({ namespaced: true })
export default class extends VuexModule {
  isLoadingContent = false;
  socket: Socket | null = null;
  providersList: SupportedProviders[] = [];
  userLoginStatus?: boolean;

  get isLogged(): boolean {
    return this.userLoginStatus || isLogged();
  }

  get authToken(): Nullable<string> {
    return this.isLogged ? localStorage.getItem('at') : null;
  }

  private get JWToken(): Optional<JWToken> {
    if (!this.authToken) {
      return undefined;
    }

    try {
      return decodeJWT(this.authToken);
    } catch (err) {
      return undefined;
    }
  }

  get userRole(): Optional<Role> {
    return this.JWToken?.role;
  }

  get sessionExpirationTime(): Optional<number> {
    return this.JWToken?.exp && this.JWToken.exp * 1000;
  }

  get isRebel(): boolean {
    return this.userRole !== 'user';
  }

  @Action({ commit: 'setLoginStatus' })
  async login({ username, password }: LoginPayload): Promise<boolean> {
    if (await AuthService.login(username, password)) {
      return true;
    } else {
      this.context.commit('notification/showError', 'auth.wrongData', { root: true });
      return false;
    }
  }

  @Action({ commit: 'setLoginStatus' })
  async logout(): Promise<boolean> {
    localStorage.removeItem('at');

    this.socket?.close();

    router.currentRoute.name !== 'login' && router.push({ name: 'login' }).catch(() => null);

    return false;
  }

  @Mutation
  setLoginStatus(state: boolean): void {
    this.userLoginStatus = state;

    if (!state) {
      this.socket = null;
    }
  }

  @Action
  async resetPassword(email: string): Promise<void> {
    const sent = await AuthService.reset(email, getLang());
    this.context.commit(
      sent ? 'notification/showNotification' : 'notification/showError',
      sent ? 'auth.instructions' : 'auth.noUser',
      { root: true }
    );
  }

  @Action
  async setNewPassword({ token, password }: SetNewPasswordPayload): Promise<void> {
    const changed = await AuthService.setNewPassword(token, password);
    this.context.commit(
      changed ? 'notification/showNotification' : 'notification/showError',
      changed ? 'auth.passChanged' : 'auth.passChangedError',
      { root: true }
    );
    changed && router.push({ name: 'login' }).catch(() => null);
  }

  @Action({ commit: 'setSocket' })
  initSocket(): Socket {
    const socket = io(process.env.VUE_APP_SOCKETHOST as string, {
      path: process.env.VUE_APP_SOCKETPATH,
      auth: {
        token: this.authToken,
      },
      transports: ['websocket', 'polling'],
    });

    socket.on('error', (data) => {
      // eslint-disable-next-line no-console
      console.log('@@socket error', data);
    });

    socket.on('reconnect', () => {
      this.context.dispatch('orders/getOrders', {}, { root: true });
    });

    return socket;
  }

  @Mutation
  setSocket(socket: Socket): void {
    this.socket = socket;
  }

  @Action({ commit: 'setProvidersList' })
  async getProvidersList(): Promise<Nullable<SupportedProviders[]>> {
    return ProvidersService.getList();
  }

  @Mutation
  setProvidersList(providersList: SupportedProviders[]): void {
    this.providersList = providersList;
  }

  @Mutation
  setLoadingStatus(status: boolean): void {
    this.isLoadingContent = status;
  }
}
