/* eslint-disable @typescript-eslint/camelcase */
export default {
  locations: 'Locais',
  filterCountry: 'países filtrantes',
  filterCity: 'cidades filtrantes',
  removeConfirm: 'Tem a certeza de querer apagar o local seleccionado e todos os seus dados?',
  filterClosed: 'Fechado',

  turnOff: 'local de desligamento',
  turnOn: 'ligue o local',

  confirmOn: 'Tem a certeza de que quer ligar o local?',
  confirmOff: 'Tem a certeza de que quer desligar o local?',

  add: 'Criar um novo local',
  edit: 'Editar dados de localização',

  companyName: 'Nome da empresa',
  street: 'Rua',
  no: 'Número',
  city: 'Cidade',
  zip: 'Código postal',
  contact: 'Detalhes de contacto',
  comment: 'Comentário',
  guideline: 'Instruções para o correio',

  deliveries: 'Editar fornecedores',
  deliveryResp: 'Responsável pelo fornecimento',

  name: 'Nome',

  contactPerson: 'Pessoa de contacto:',

  removed: 'O local foi removido',
  added: 'A localização foi acrescentada',

  countries: {
    pl: 'Polónia',
    ua: 'Ucraina',
    pt: 'Portugal',
    cy: 'Chipre',
  },

  languages: {
    pl: 'Polonês',
    ua: 'Ucraniano',
    en: 'Inglês',
  },

  couriers: {
    delivery_by_provider: 'mercado',
    delivery_by_restaurant: 'restaurant',
    delivery_by_ld: 'Lets Deliver',
    delivery_by_stava: 'Stava',
    delivery_by_stuart: 'Stuart',
    delivery_by_deligoo: 'Deligoo',
  },

  phone: 'Phone no',

  addHolidays: 'Add holiday',
  holidayAdded: 'The holiday has been added',
  allowSmsNotifications: 'Permitir notificações por SMS',
  notificationPhone: 'Número de telefone para receber notificações por SMS',
};
