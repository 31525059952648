import { Sound } from "@/interfaces";

export default function beep(loop = false, sound: Sound = 'newalert'): HTMLAudioElement | null {
  try {
    const audio = new Audio(`${ sound }.mp3`);
    audio.loop = loop;
    audio.play();

    return audio;
  } catch(err) {
    console.log(err);
    return null;
  }
}
