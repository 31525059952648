import { Component, Vue } from 'vue-property-decorator'
import beep from '@/helpers/beep';

@Component
export default class BeepOnOpen extends Vue {
  private beep: HTMLAudioElement | null = null;

  mounted(): void {
    this.beep = beep(true);
  }

  beforeDestroy(): void {
    this.beep?.pause();
    this.beep = null;
  }
}
