/* eslint-disable @typescript-eslint/explicit-function-return-type */

import Vue from 'vue'
import VueRouter from 'vue-router'
import { RouteConfigSingleView } from 'vue-router/types/router';

import store from '@/store'
import { rebels } from '@/configs';
import { Role } from '@/interfaces';

Vue.use(VueRouter)

interface Route extends RouteConfigSingleView {
  meta?: {
    roles?: Role[];
    requiresAuth?: boolean;
  };
}

const routes: Route[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/restaurants',
    name: 'restaurants',
    component: () => import('@/views/Restaurants.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/restaurant/:restaurantId',
    name: 'restaurant',
    component: () => import('@/views/Restaurant.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('@/views/Stats.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import('@/views/Locations.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/location/:id',
    name: 'location',
    component: () => import('@/views/Location.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/concept/:id',
    name: 'concept',
    component: () => import('@/views/Concept.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Orders.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/remind',
    name: 'remind',
    component: () => import('@/views/auth/Remind.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/auth/Reset.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/concepts',
    name: 'uconcepts',
    component: () => import('@/views/Concepts.vue')
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/Status.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/ws',
    name: 'ws',
    component: () => import('@/views/Ws.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/orders/raw/:id',
    name: 'orderRaw',
    component: () => import('@/views/OrderRaw.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/Logs.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/restaurant-feed',
    name: 'restaurant-feed',
    component: () => import('@/views/RestaurantFeed.vue'),
    meta: { roles: rebels }
  },
  {
    path: '/resources/:type(brand|region)',
    name: 'resources',
    component: () => import('@/views/Resources.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/holidays',
    name: 'holidays',
    component: () => import('@/views/Holidays.vue'),
    meta: { roles: rebels }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next): void => {
  const isLogged = store?.getters['app/isLogged'];
  const userRole = store?.getters['app/userRole'];

  (to?.meta?.requiresAuth !== false && !isLogged) || (to?.meta?.roles && !to?.meta?.roles.includes(userRole))
    ? next({ name: 'login' })
    : next();
});

export default router
