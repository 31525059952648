
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import beepOnOpen from '@/mixins/beepOnOpen';
  import { OrderModel } from '@/models';

  const ordersStore = namespace('orders');

  @Component
  export default class NewOrdersModal extends Mixins(beepOnOpen) {
    @Prop({ required: true }) readonly order!: OrderModel;

    @ordersStore.State
    readonly orders!: OrderModel[];

    get newOrdersQua(): number {
      return this.orders.filter(order => order.status === this.order.status).length;
    }

    closeModal(): void {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ name: 'orders', query: { status: this.order.status, orderId: ''+this.order.id } }).catch(() => {});
      this.$emit('close');
    }
  }
