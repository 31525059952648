import { Nullable, User } from '@/interfaces';
import apiService from './api';

class UsersService extends apiService{
  findByLocation(locationId: number): Promise<Nullable<User[]>> {
    return this.get('users/' + locationId);
  }

  async remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`users/${ id }`);
  }

  async update(user: User): Promise<Nullable<boolean>> {
    return this.patch(`users/${ user.id }`, user);
  }

  add(user: Partial<User>, locationId?: number): Promise<Nullable<User>> {
    return this.post('users', {
      ...user,
      locationId,
    });
  }

  getUsers(): Promise<Nullable<User[]>> {
    return this.get('users');
  }
}

export default new UsersService
