import { toLocale } from "@/helpers/date";
import { Log } from "@/interfaces";
import store from '@/store';
import { ConceptModel } from "./concept.model";

export class LogModel implements Log {
  id!: number;
  conceptId!: number;
  userId!: number;
  logType!: string;
  data!: string | null;
  createdAt!: string;

  public constructor(log: Partial<Log>) {
    Object.assign(this, log);

    this.createdAt = toLocale(this.createdAt);
    this.data = this.data === 'null' ? null : this.data;
  }

  public conceptName(): string | null {
    const concept: ConceptModel = store.state.concepts.conceptsForUser?.find((item: ConceptModel) => item.id === this.conceptId);
    return concept ? `${ concept.locationName } ${ concept.name }` : null;
  }
}
