import { getLang } from '@/helpers/user';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { pl, en, pt, uk } from 'vuetify/src/locale'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pl, en, pt, uk },
    current: getLang(),
  },
});
