export default {
  locations: {
    header: 'Локації',
    qua: 'Калькість локацій',
    restaurantsQua: 'Калькість ресторанів',
    profilesNumber: 'Кількість профілів',
    openedQua: 'Відкриті',
    closedQua: 'Закриті',
    running: 'Діючі ресторани',
  },

  orders: {
    todays: 'Сьогоднішні замовлення',
    orders: 'Замовлення ',
    rejected: 'Відхилені',
    specialOffer: 'Спеціальна пропозииція ',
    freeDelivery: 'Безкоштовна доставка ',
    yesterdays: 'Вчорашні замовлення',
  }
}
