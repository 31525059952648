export default {
  holidays: 'Вихідні дні',
  dates: 'Дати',
  location: 'Локація',

  addHolidays: 'Додати вихідний день',

  added: 'Вихідний день додано',
  removed: 'Вихідний день видалено',
}
