export interface MenuItem {
  id: number;
  name: string;
  categories?: MenuCategory[];
  modifiers?: number[];
  price: number;
  active?: boolean;
  until?: TurnItemOffUntil;
  exclude?: boolean;
  type: MenuItemType;
  components?: string;
  photo?: string;
  desc?: string;
  size?: string;
  required?: boolean;
}

export interface MenuCategory {
  id: number;
  name: string;
  items: MenuItem[];
  type: MenuCategoryType;
  value?: number;
  quaMin?: number;
  quaMax?: number;
  resetPrices?: boolean;
  order?: number;
}

export enum MenuCategoryType {
  'products' = 'products',
  'modifiers' = 'modifiers',
}

export enum ChangeOrderType {
  'category' = 'category',
  'item' = 'item',
}

export enum TurnItemOffUntil {
  permanent = 'permanent',
  endOfDay = 'endOfDay',
}

export interface ChangeItemsAvailability {
  state: boolean;
  until?: TurnItemOffUntil;
}

export interface ToggleItem extends ChangeItemsAvailability {
  id: number;
}

export type MenuCategoryWithAvailability = Omit<MenuCategory, 'items'> & {
  items: MenuItem[];
};

export enum MenuItemType {
  'item' = 'item',
  'set' = 'set',
}
