import { Location, LocationDelivery, SupportedCountry, SupportedLanguages } from '@/interfaces';

export class LocationModel implements Location {
  id!: number;
  name!: string;
  contact!: string;
  street!: string;
  no!: string;
  city!: string;
  zip!: string;
  comment!: string;
  deliveryTypes!: LocationDelivery[];
  country!: string;
  notificationPhone?: string;
  allowNotifications!: boolean;
  notificationLang!: SupportedLanguages;

  public constructor(location: Partial<Location>) {
    Object.assign(this, location);
  }

  public getCurrencyFormat(): string {
    const currencies: Record<SupportedCountry, string> = {
      pl: '{value} zł',
      ua: '₴{value}',
      pt: '€{value}',
      cy: '€{value}',
    };

    return currencies[this.country];
  }
}
