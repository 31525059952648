export default {
  duplicatedEmail: 'Вказана електронна адреса вже існує',
  nameDuplicated: 'Дане ім’я вже існує в системі',
  wrongStatus: 'Наведений статус неправильний',
  wrongRejectionReason: 'Причину відхилення вказано невірно',
  duplicatedMenuItem: 'Зазначений товар уже додано до вибраної категорії',
  cannotRemoveCurrentMenu: 'Поточне меню не можна видалити, оскільки воно є частиною існуючих замовлень',
  partnerIDinUse: 'Введений вами ID партнера вже використовується.',
  'Status not changed': 'Статус замовлення не змінено. Перезапустіть програму та повторіть спробу.',
  'Order is already rejected': 'Статус замовлення не можна оновити, оскільки замовлення вже відхилено.',
}
