export default {
  login: 'Логін',
  email: 'Адреса е-мейл',
  password: 'Пароль',
  dontRemember: 'Забули пароль?',
  loginBtn: 'Увійти',

  remind: 'Зміна пароля',
  reset: 'Скинути пароль',

  setNewPassword: 'Встановити новий пароль',
  repeatPassword: 'Повторіть введений пароль',
  save: 'Зберегти',

  noCredentials: 'У Вас немає необхідного дозволу',
  wrongData: 'Введені дані неправильні ',
  instructions: 'Інструкція щодо встановлення пароля була відправлена на Вашу електронну пошту.',
  noUser: 'Подана електронна адреса не існує в нашій базі даних',
  passChanged: 'Пароль було успішно змінено ',
  passChangedError: 'Не вийшло змінити Ваш пароль',
}
