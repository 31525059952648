import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { Nullable, User } from '@/interfaces';
import { UsersService } from '@/services';
import { AddUserPayload, UpdateUserPayload } from './interfaces';

@Module({ namespaced: true })
export default class extends VuexModule {
  public users: User[] = [];

  @MutationAction({ mutate: ['users'] })
  public async getUsers(locationId: number): Promise<{ users: Nullable<User[]> }> {
    const users = await (locationId
      ? UsersService.findByLocation(locationId)
      : UsersService.getUsers() )

    return {
      users
    }
  }

  @Action({ commit: 'removeUserMutation' })
  public async removeUser(id: number): Promise<Nullable<number>> {
    if (await UsersService.remove(id)) {
      this.context.commit('notification/showNotification', 'users.removed', { root: true });

      return id;
    }

    return null;
  }

  @Mutation
  removeUserMutation(id: number): void {
    this.users = this.users.filter(item => item.id !== id);
  }

  @Action({ commit: 'updateUserMutation' })
  public async updateUser({ user }: UpdateUserPayload): Promise<Nullable<User>> {
    if( await UsersService.update(user)) {
      this.context.commit('notification/showNotification', 'users.updated', { root: true });

      return user;
    }

    return null;
  }

  @Mutation
  updateUserMutation(user: User): void {
    const users = [ ...this.users ];
    const index = users.findIndex(_item => _item.id === user.id);
    users[index] = {...users[index], ...user};
    this.users = users;
  }

  @Action({ commit: 'addUserMutation'})
  public async addUser({ locationId, user }: AddUserPayload): Promise<Nullable<User>> {
    const item = await UsersService.add(user, locationId);

    if (item) {
      this.context.commit('notification/showNotification', 'users.added', { root: true });

      return item;
    }

    return null;
  }

  @Mutation
  addUserMutation(user: User): void {
    user && this.users.push(user);
  }
}
