import { SortReturnValues, TextValuePair } from "@/interfaces";
import { sortByName } from "./sort";

export const sortStringByName = (a = '', b = ''): SortReturnValues => a == b ? 0 : a > b ? 1 : -1;

export function removeDuplicates<T>(item: T, ndx: number, all: T[]): boolean {
  return ndx === all.indexOf(item);
}

export function toTextValuePair<T>(array: T[], text: keyof T, value: keyof T): TextValuePair[] {
  return array.map(item => ({
      text: item[text] as string,
      value: item[value] as string
    }))
    .sort(sortByName);
}
