import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { HolidaysService } from '@/services';
import { Holiday, Nullable } from '@/interfaces';
import { AddHolidayPayload } from './interfaces';

@Module({ namespaced: true })
export default class HolidaysStore extends VuexModule {
  @Action
  async getHolidays(): Promise<Nullable<Holiday[]>> {
    return HolidaysService.findAll();
  }

  @Action
  async add(data: AddHolidayPayload): Promise<Nullable<Holiday[]>> {
    const result = await HolidaysService.add(data);
    if (result) {
      this.context.commit('notification/showNotification', 'holidays.added', { root: true });
    }

    return result;
  }

  @Action
  async remove(id: number): Promise<boolean> {
    if (await HolidaysService.remove(id)) {
      this.context.commit('notification/showNotification', 'holidays.removed', { root: true });
      
      return true;
    }

    return false;
  }
}
