import { QueryFeeds, QueryLocations } from '../store/interfaces';

export function buildQuery(url: string, query?: QueryLocations | QueryFeeds): string {
  if (!query) return url;
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(query)) {
    params.append(key, value);
  }

  return `${url}?${params}`;
}
