import axios, { AxiosError } from 'axios';
import store from '@/store';
import { Nullable } from '@/interfaces';

type HttpMethod = 'post' | 'get' | 'delete' | 'patch';

export default class ApiService {
  constructor() {
    const accessToken = localStorage.getItem('at');
    accessToken && this.updateAccessToken(accessToken);

    axios.interceptors.request.use(config => {
      store.commit('app/setLoadingStatus', true)
      return config;
    }, err => {
      store.commit('app/setLoadingStatus', false)
      return Promise.reject(err)
    });
    axios.interceptors.response.use(response => {
      store.commit('app/setLoadingStatus', false)
      return response;
    }, err => {
      store.commit('app/setLoadingStatus', false)
      return Promise.reject(err)
    });
  }

  get<TResult>(endpoint: string): Promise<Nullable<TResult>> {
    return this.apiCall(endpoint, undefined, undefined, 'get');
  }

  patch<TResult, TData>(endpoint: string, data: TData, headers = {}): Promise<Nullable<TResult>> {
    return this.apiCall(endpoint, data, headers, 'patch');
  }

  post<TResult, TData>(endpoint: string, data: TData, headers = {}): Promise<Nullable<TResult>> {
    return this.apiCall(endpoint, data, headers, 'post');
  }

  delete<TResult>(endpoint: string): Promise<Nullable<TResult>> {
    return this.apiCall(endpoint, undefined, undefined, 'delete');
  }

  private async apiCall<TResult, TData>(endpoint: string, data?: TData, headers?: Record<string, string>, method?: HttpMethod): Promise<Nullable<TResult>> {
    try {
      method = method ? method : (data ? 'post' : 'get');

      const result = await axios({
        method,
        url: process.env.VUE_APP_APIURL + endpoint,
        data,
        headers,
      });

      return result.data || [200, 201].includes(result.status);

    } catch(_err: any) {
      const err: AxiosError = _err;
      switch (err?.response?.status) {
        case 400:
          store.commit('notification/showError', `errors.${ err.response.data.message }`);
        break;
        case 401:
          store.dispatch('app/logout');
        break;
        case 403:
          store.commit('notification/showError', 'auth.noCredentials');
        break;
        case 404:
          store.commit('notification/showError', 'general.404');
        break;
        default:
          store.commit('notification/showError', 'general.500');
          // eslint-disable-next-line no-console
          console.error('Network error:', err);
        break;
      }
    }

    return null;
  }

  updateAccessToken(accessToken: string): void {
    axios.defaults.headers.common = {'Authorization': `Bearer ${ accessToken }`};
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataToFormData(data: Record<string, any>): FormData {
    const formData = new FormData;
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    return formData;
  }

  fileUploadHeaders(): Record<string, string> { 
    return { 'Content-Type': 'multipart/form-data'};
  }
}
