export default {
  holidays: 'Urlopy',
  dates: 'Daty',
  location: 'Lokalizacja',

  addHolidays: 'Dodaj urlop',

  added: 'Urlop został dodany',
  removed: 'Urlop został usunięty',
}
