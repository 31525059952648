export interface Log {
  id: number;
  conceptId?: number;
  userId?: number;
  orderId?: number;
  logType: string;
  data: string | null;
  createdAt: string;
}

export enum LogType {
  'USER_LOGGED' = 'USER_LOGGED',

  'STORE_STATUS' = 'STORE_STATUS',
  'STORE_POS_STATUS' = 'STORE_POS_STATUS',
  'STORE_STATUS_CHANGED' = 'STORE_STATUS_CHANGED',
  'STORE_POS_STATUS_CHANGED' = 'STORE_POS_STATUS_CHANGED',
  
  'COURIER_ORDER' = 'COURIER_ORDER',
  'COURIER_ORDER_STATUS' = 'COURIER_ORDER_STATUS',

  'PROVIDER_ERROR' = 'PROVIDER_ERROR'
}

export interface LogFiltersOptions {
  locationId?: number;
  conceptsIds?: number[];
  lastId?: number;
  userId?: number;
  orderId?: number;
  types?: LogType[];
}
