import { SupportedCountry } from "./supported-country.enum";

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  role: Role;
  token: string;
  countries?: SupportedCountry[];
  resources?: UserResource[];
  resourcesIds?: number[];
}

export interface UserResource {
  resourceId: number;
}

export const roles = ['user', 'admin', 'country', 'regional', 'brand'] as const;
export type Role = typeof roles[number];
