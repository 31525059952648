
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class TurningOnBanner extends Vue {
    @Prop({ required: true }) name!: string;
    @Prop({ required: true }) time!: string;

    visible = true;
  }
