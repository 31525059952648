/* eslint-disable @typescript-eslint/camelcase */
export default {
  locations: 'Locations',
  filterCountry: 'countries',
  filterCity: 'cities',
  filterRegions: 'regions',
  filterOptions: 'filters',
  removeConfirm: 'are you sure you want to delete the selected location and all its data?',
  filterClosed: 'closed',

  turnOff: 'turn off the location',
  turnOn: 'turn om location',

  confirmOn: 'Are you sure you want to turn on location?',
  confirmOff: 'Are you sure you want to turn off location?',

  indicator: 'Location online indicator',
  masterSwitch: 'Location master status',

  add: 'Creating a new location',
  edit: 'Edit location data',

  companyName: 'Company name',
  street: 'Street',
  no: 'Number',
  city: 'City',
  zip: 'Postal code',
  contact: 'Contact details',
  comment: 'Comment',
  guideline: 'Instructions for courier',

  deliveries: 'Edit suppliers',
  deliveryResp: 'Responsible for supply',

  name: 'Name',
  id: 'ID',

  contactPerson: 'Contact person:',

  removed: 'The location has been removed',
  added: 'Location has been added',

  countries: {
    pl: 'Poland',
    ua: 'Ukraine',
    pt: 'Portugal',
    cy: 'Cyprus',
  },

  languages: {
    pl: 'Polish',
    ua: 'Ukrainian',
    en: 'English',
  },

  couriers: {
    delivery_by_provider: 'marketplace',
    delivery_by_restaurant: 'restaurant',
    delivery_by_ld: 'Lets Deliver',
    delivery_by_stava: 'Stava',
    delivery_by_stuart: 'Stuart',
    delivery_by_deligoo: 'Deligoo',
    delivery_by_woltdrive: 'WoltDrive',
  },

  phone: 'Phone no',

  addHolidays: 'Add holiday',
  holidayAdded: 'The holiday has been added',

  allowSmsNotifications: 'Allow SMS notifications',
  notificationPhone: 'Phone number to receive SMS notifications',
};
