
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import Notification from '@/components/Notification.vue';
  import NewOrderModal from '@/components/modals/NewOrder.vue';
  import OfflineModal from '@/components/modals/Offline.vue';
  import OfflineBanner from '@/components/banners/Offline.vue';
  import NewVersionBanner from '@/components/banners/NewVersion.vue';
  import TurningOnBannerGroup from '@/components/banners/TurningOnBannerGroup.vue';
  import LandscapeModal from '@/components/modals/Landscape.vue';
  import MessageModal from '@/components/modals/Message.vue';
  import LangSwitcher from '@/components/LangSwitcher.vue';
  import AppVersion from '@/components/AppVersion.vue';

  import beep from '@/helpers/beep';
  import { MenuPosition, MessageBox, Optional, OrderStatus, Role, Sound } from './interfaces';
  import { ConceptModel, OrderModel } from './models';
  import { rebels } from './configs';
  import { getDiff } from './helpers/date';
import orders from './services/orders';

  const appStore = namespace('app');
  const ordersStore = namespace('orders');
  const conceptsStore = namespace('concepts');
  const locationsStore = namespace('locations');
  const notificationStore = namespace('notification');

  interface Socket {
    on(event: string, callback: Function): void;
    off(event: string, callback: Function): void;
  }

  @Component({ components: { Notification, NewOrderModal, OfflineModal, OfflineBanner, NewVersionBanner, MessageModal, LandscapeModal, LangSwitcher, AppVersion, TurningOnBannerGroup } })
  export default class App extends Vue {
    showNewOrderModal: OrderModel | null = null;
    showOfflineModal = false;
    isOnline = true;
    newVersionAvailable = false;
    isInLandscapeMode = true;
    clock = new Date;

    get menuGroups(): MenuPosition[][] {
      const menuRebel: MenuPosition[][] = [[
        { icon: 'mdi-home', route: 'home' },
        { icon: 'mdi-home-city-outline', route: 'restaurants', onlyFor: ['admin', 'brand', 'country']},
        { icon: 'mdi-map-marker', route: 'locations' },
        { icon: 'mdi-chart-areaspline', route: 'stats' },
        { icon: 'mdi-account', route: 'users', onlyFor: ['admin', 'brand'] },
        { icon: 'mdi-currency-usd', route: 'orders' },
        { icon: 'mdi-cog-transfer', route: 'ws', onlyFor: ['admin'] },
        { icon: 'mdi-format-list-bulleted', route: 'logs', onlyFor: ['admin'] },
        { icon: 'mdi-list-status', route: 'restaurant-feed', onlyFor: rebels },
        { icon: 'mdi-book-open-outline', route: { name: 'resources', params: { type: 'region' } } , onlyFor: ['admin'] },
        { icon: 'mdi-calendar-range', route: { name: 'holidays' } , onlyFor: ['admin', 'regional'] },
      ],
      [
        { icon: 'mdi-access-point', route: 'status' }
      ]];

      const menuUser: MenuPosition[][] = [
        [
          { icon: 'mdi-book-plus-outline', route: { name: 'orders',  query: { status: 'new' } }, orderStatus: OrderStatus.new },
          { icon: 'mdi-book-clock-outline', route: { name: 'orders',  query: { status: 'processing' } }, orderStatus: OrderStatus.processing },
          { icon: 'mdi-package-variant-closed', route: { name: 'orders',  query: { status: 'ready_for_pickup' } }, orderStatus: OrderStatus.ready_for_pickup },
          { icon: 'mdi-book-check-outline', route: { name: 'orders',  query: { status: 'finished' } } },
          { icon: 'mdi-bookmark-check-outline', route: { name: 'orders', query: { status: 'finished_auto' } }, orderStatus: OrderStatus.finished_auto }
        ],
        [
          { icon: 'mdi-currency-usd', route: 'orders', mini: true },
          { icon: 'mdi-chart-areaspline', route: 'stats', mini: true },
          { icon: 'mdi-cog', route: 'uconcepts', mini: true },
        ]
      ];

      const menu = (this.isRebel ? menuRebel : menuUser).map(menu =>
        menu.filter(menuItem => !menuItem.onlyFor || menuItem.onlyFor.includes(this.userRole))
      );

      return menu;
    }

    get conceptsToTurnOn(): ConceptModel[] {
      const now = ''+this.clock

      return this.conceptsForUser.filter(item => {
        const statuses = item.storeStatus && Object.values(item.storeStatus);
        if (!statuses || !statuses[0]?.until) {
          return false;
        }

        return getDiff(statuses[0]?.until, now) <= 15;
      });
    }

    @appStore.Getter
    readonly isLogged!: boolean;

    @appStore.State
    readonly isLoadingContent!: boolean;

    @appStore.Getter
    readonly userRole!: Role;

    @appStore.Getter
    readonly isRebel!: boolean;

    @appStore.State
    readonly socket!: Socket;

    @appStore.Getter
    readonly sessionExpirationTime: Optional<number>;

    @ordersStore.State
    readonly ordersStats!: Record<OrderStatus, number>;

    @ordersStore.State('realTime')
    readonly adminRealTime!: boolean;

    @conceptsStore.State
    readonly conceptsForUser!: ConceptModel[];

    @Watch('userRole')
    onUserRoleChanged(val: string): void {
      val && this.loadUserData();
    }

    @Watch('adminRealTime')
    onAdminRealTimeChanged(val: boolean): void {
      if (this.userRole === 'user') return;

      val
        ? this.socket.on('newOrder', this.handleNewOrder)
        : this.socket.off('newOrder', this.handleNewOrder);
    }

    mounted(): void {
      window.RebelApp = true;

      this.isLogged && this.loadUserData();

      window.addEventListener('online', this.onlineStatusChanged);
      window.addEventListener('offline', this.onlineStatusChanged);

      window.addEventListener('orientationchange', this.onOrientationChange)
      this.onOrientationChange();

      setInterval(() => this.clock = new Date, 60000);
    }

    @appStore.Action('logout')
    logoutUser!: () => void;

    @appStore.Action
    initSocket!: () => Promise<void>;

    @ordersStore.Action
    getOrdersStats!: () => Promise<void>;

    @ordersStore.Mutation
    setOrdersRequiredSync!: (orderId: number | number[]) => void;

    @conceptsStore.Action('getForUser')
    getConceptsForUser!: (withStoreStatuses: boolean) => Promise<void>;

    @appStore.Action
    getProvidersList!: () => Promise<void>;

    @locationsStore.Action
    getCountries!: () => Promise<void>;

    @notificationStore.Mutation
    showMessageBox!: (data: MessageBox | string) => void;

    @notificationStore.Mutation
    resetMessageBox!: () => void;

    @ordersStore.Mutation
    addOrder!: (order: OrderModel) => Promise<void>;

    logout(): void {
      this.logoutUser();
    }

    loadUserData(): void {
      if (this.userRole === 'user') {
        this.showMessageBox(this.$t('general.interaction') as string);
        this.getOrdersStats();
      } else {
        this.getCountries();
      }

      !this.socket && this.initWS();
      this.getConceptsForUser(!this.isRebel);
      this.getProvidersList();
      this.handleSessionExpiration();
    }

    async initWS(): Promise<void> {
      await this.initSocket();
      if (this.userRole === 'user') {
        this.socket.on('newOrder', this.handleNewOrder);
        this.socket.on('orderError', this.handleOrderError);
      }

    this.socket.on('courierStatusChanged', this.handleNewOrderStatus);
    this.socket.on('newVersionAvailable', this.handleNewVersion);
  }

    handleNewOrderStatus(order: OrderModel): void {
      this.setOrdersRequiredSync(order.id)
    }

    onlineStatusChanged(): void {
      this.isOnline = navigator.onLine;
      this.showOfflineModal = !navigator.onLine;
    }

    handleNewOrder(order: OrderModel): void {
      this.showNewOrderModal = order;
      this.addOrder(order);
    }

    handleOrderError(order: OrderModel): void {
      this.addOrder(order);
      this.$router.push({ name: 'orders', query: { status: order.status, orderId: order.id.toString() }}).catch(() => null);

      this.startBeeping();
    }

    startBeeping(sound: Sound = 'newalert'): void {
      const beeper = beep(true, sound);
      window.addEventListener('click', () => {
        beeper?.pause();
      }, { once: true } );
    }

    handleNewVersion({ hard }: { hard: boolean }): void {
      if (hard) {
        window.location.reload();
      } else {
        this.newVersionAvailable = true;
      }
    }

    onOrientationChange(): void {
      this.isInLandscapeMode = !screen.orientation || ['landscape-primary', 'landscape-secondary'].includes(screen.orientation.type);
    }

    handleSessionExpiration(): void {
      if (!this.sessionExpirationTime) {
        return;
      }

      const now = (new Date).getTime();
      const timeout = this.sessionExpirationTime - now;

      setTimeout(() => {
        // this.startBeeping('logout');
        this.logoutUser();
      }, timeout);
    }
  }
