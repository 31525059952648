export default {
  duplicatedEmail: 'Podany adres email istnieje już w systemie',
  nameDuplicated: 'Podana nazwa istnieje już w systemie',
  wrongStatus: 'Podany status jest nieprawidłowy',
  wrongRejectionReason: 'Podany powód odrzucenia jest nieprawidłowy',
  duplicatedMenuItem: 'Podany produkt jest już dodany do wybranej kategorii',
  cannotRemoveCurrentMenu: 'Nie można usunąć aktualnego menu, ponieważ wchodzi ono w skład istniejących zamówień',
  partnerIDinUse: 'Podany identyfikator Partnera jest już używany.',
  'Status not changed': 'Status zamówienia nie został zmieniony. Odśwież aplikację i spróbuj ponownie.',
  'Order is already rejected': 'Nie można zmienić statusu zamówienia, ponieważ zostało już ono odrzucone.',
}
