export default {
  regions: 'Regiony',
  brands: 'Marki',

  region: 'Region',
  brand: 'Marka',

  add: {
    region: 'Dodaj region',
    brand: 'Dodaj markę',
  },

  edit: {
    region: 'Edytuj region',
    brand: 'Edytuj markę',
  },
}
