export default {
  statsBy: 'Estatísticas de encomendas por',
  orders: 'Encomendas',
  locations: 'localização',
  restaurants: 'restaurantes',
  menu: 'menu',
  today: 'hoje',
  lastWeek: 'na semana passada',
  lastMonth: 'na semana passada',
  custom: 'gama manual',
  location: 'Localização',
  restaurant: 'Restaurante',
  menuItem: 'Item do menu',
  showEmpty: 'Mostrar restaurantes sem pedidos',
  showInternal: 'Mostrar restaurantes internos'
}
