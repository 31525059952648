import { Role } from "@/interfaces";

export const rebels: Role[] = ['admin', 'country', 'brand', 'regional'];

type ComponentName = string;

export const accessList: Record<ComponentName, Role[]> = {
  'add-location': ['brand', 'country'],
  'delete-location': ['brand', 'country'],
  'update-delivery': ['country', 'brand'],
  'files': ['country'],
  'users': ['country', 'brand'],
  'add-concept': ['country'],
  'remove-concept': ['brand', 'country'],
  'edit-concept': ['country'],
  'order-courier': ['user', 'country'],
  'order-change-status': ['user', 'country', 'brand'],
  'order-raw': [],
  'copy-restaurant': ['country'],
  'regenerate-thumbs': [],
  'menu-importer': [],
  'real-time-orders': [],
  'status-history': rebels,
  'concept-status': rebels,
  'location-status-switch': rebels,
  'countries-filter': ['country']
}
