export default {
  pickRestaurant: 'Escolher restaurantes',
  removeConfirm: 'Tem a certeza de que quer apagar o restaurante e todos os seus dados?',
  restaurants: 'Restaurantes',
  statuses: 'Estatuto dos restaurantes',

  integrationOnConfirm: 'Tem a certeza de que quer ligar a integração?',
  integrationOffConfirm: 'Tem a certeza de que quer desactivar a integração?',

  turnOn: 'Tem a certeza de que quer ligar o restaurante?',
  turnOff: 'Tem a certeza de que quer desactivar o restaurante?',

  editConcept: 'Conceito de edição',
  addConcept: 'Adicionar conceito',

  copy: 'Cópia de restaurante',
  newName: 'Novo nome',

  add: 'Criação de um novo restaurante',
  edit: 'Edição de dados de restaurantes',

  name: 'Nome',
  logo: 'Logótipo',
  desc: 'Descrição',
  prepTime: 'Tempo de preparação das refeições',
  externalRestaurant: 'Restaurante externo',

  editWht: 'Editar horário de abertura',
  from: 'A partir de',
  to: 'Até',

  days: ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'],

  concepts: 'Conceitos',
  confirmRemConcept: 'Tem a certeza de que quer retirar o conceito da sua localização actual?',

  confirmRemove: 'Tem a certeza de que quer eliminar o restaurante e todos os seus dados?',

  wh: 'Horário de trabalho',

  conceptAdded: 'O conceito foi acrescentado',
  conceptRemovedFromLoc: 'O conceito foi removido do local',
  whChanged: 'As horas de trabalho foram alteradas',
  avabilityChanged: 'A disponibilidade foi alterada',
  syncStarted: 'A sincronização foi iniciada',
  restaurantOn: 'O restaurante foi activado',
  restaurantOff: 'O restaurante foi desligado',
  integrationOn: 'A integração foi permitida',
  integrationOff: 'A integração foi desactivada',
  locationOn: 'O local foi activado',
  locationOff: 'A localização foi desligada',

  removed: 'O restaurante foi removido',
  added: 'O restaurante foi acrescentado',

  restaurantTypes: [
    { text: 'restauracja', value: 'restaurant' },
    { text: 'sklep', value: 'shop' },
  ],

  turningOffReasons: {
    'busy': 'busy',
    'hardware_failure': 'hardware failure',
    'internet_problem': 'internet problem',
    'missing_items': 'missing _items',
    'changed_by_marketplace': 'changed by marketplace',
    'holidays': 'holiday',
    'auto': 'changed automatically',
  },

  turningOffDurations: {
    'permanent': 'permanently',
    '1h': '1h',
    '2h': '2h',
    '3h': '3h',
    'endOfDay': 'end of day',
  },

  turningOffHeaders: {
    turnOffDuration: 'turning off duration',
    turnOffReason: 'turning off reason',
  },
}
