export default {
  duplicatedEmail: 'The specified email address already exists in the system',
  nameDuplicated: 'The given name already exists in the system',
  wrongStatus: 'The given status is incorrect',
  wrongRejectionReason: 'The reason given for rejection is incorrect',
  duplicatedMenuItem: 'The specified product has already been added to the selected category',
  cannotRemoveCurrentMenu: 'The current menu cannot be deleted because it is part of existing orders',
  partnerIDinUse: 'The Partner ID you entered is already in use.',
  'Status not changed': 'Order status has not been changed. Please restart application and try again.',
  'Order is already rejected': 'Order status cannot be updated, because the order was already rejected.',
}
