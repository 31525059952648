export default {
  statsBy: 'Order statistics by',
  orders: 'Orders',
  locations: 'location',
  restaurants: 'restaurants',
  menu: 'menu',
  today: 'today',
  lastWeek: 'last week',
  lastMonth: 'last month',
  custom: 'manual range',
  location: 'Location',
  restaurant: 'Restaurant',
  menuItem: 'Menu item',
  showEmpty: 'Show only empty',
  showInternal: 'Show internal restaurants'
}
