export default {
  removeConfirm: 'Are you sure you want to delete the selected user?',

  edit: 'Edition',
  add: 'Adding',
  user: 'user',
  admin: 'administrator',

  firstname: 'Firstname',
  lastname: 'Lastname',
  email: 'E-mail',
  role: 'Role',

  users: 'Users',

  removed: 'The user has been deleted',
  updated: 'The user been updated',
  added: 'The user has been added',

  limitations: 'Limit access to',

  roles: {
    admin: 'admin',
    country: 'country manager',
    regional: 'regional manager',
    brand: 'brand manager',
  },
}
