import { SupportedProviders } from '@/interfaces';

export default {
  rawData: 'Otrzymane dane dla zamówienia',
  statusOrders: 'zamówienia',

  orderCanceled:
    'Zamówienie zostało anulowane. Niestety {source} nie obsługuje automatycznego anulowania zamówień, dlatego konieczne jest anulowanie zamówienia za pośrednictwem call center',
  orderid: 'ID zamówienia',
  phone: 'nr telefonu:',

  orderCanceledCourier:
    'Zamówienie zostało anulowane. Pamiętaj, aby zadzwonić do firmy kurierskiej i odwołać kuriera, w przeciwnym razie zostaną naliczone koszty dostawy.',

  courierProblem: 'Wystąpił problem z kurierem',
  status: 'status',
  contactCourier: 'Skontaktuj się z firmą kurierską w celu wyjaśnienia sprawy',

  clientData: 'Dane zamawiającego',
  deliveryData: 'Dane do dostawy',
  deliveryAddress: 'Adres dostawy',
  problemWithGettingAddress:
    'Nie udało się pobrać pełnego adresu dostawy. Skontaktuj się z klientem telefonicznie, w celu ustalenia adresu dostawy.',
  comments: 'Uwagi',

  predictedPickupTime: 'Przewidywany czas odbioru',

  orderDetails: 'Szczegóły zamówienia',
  disponsable: 'Dodać sztućce itp',
  orderStatus: 'Status zamówienia',
  orderSource: 'Źródło zamówienia',
  paymentMethod: 'Sposób płatności',
  orderDate: 'Data zamówienia',
  deliveryType: 'Odbiór zamówienia',
  payByCash: 'Płatność przy odbiorze',
  courier: 'Kurier',

  statusReady: 'gotowe',
  statusDone: 'zrealizowane',

  orderCourierConfirm: 'Czy na pewno chcesz zamówić kuriera?',

  invoiceData: 'Dane do faktury',
  invoiceCompany: 'Nazwa firmy',
  invoiceAddress: 'Adres',
  invoiceTaxID: 'NIP',

  products: 'Zamówione produkty',
  allergies: 'Alergie',
  orderComment: 'Komentarz do zamówienia',

  cancelBtn: 'anuluj zamówienie',

  paymentBreakdown: 'Podsumowanie płatności',
  subtotal: 'Suma produktów',
  specialOffers: 'Wartość Promocji',
  orderValue: 'Wartość zamówienia po promocji',
  deliveryCost: 'Koszt dostawy',
  summary: 'Podsumowanie',

  newOrder: 'masz nowe zamówienia',

  location: 'lokalizacja',
  concept: 'koncepty',
  restaurant: 'restauracja',

  source: 'agregator',

  statuses: {
    new: 'nowe',
    processing: 'zaakceptowane',
    'ready_for_pickup': 'gotowe do odbioru',
    finished: 'zrealizowane',
    'finished_auto': 'zrealizowane automatycznie',
    rejected: 'odrzucone',
  },

  sources: {
    Glovo: 'Glovo',
    UberEats: 'UberEats',
    JustEat: 'JustEat',
    Wolt: 'Wolt',
    Bolt: 'Bolt',
    SocialFood: 'SocialFood',
  },

  phones: {
    'delivery_by_ld': '22 185 56 03 / 518 343 803',
    'delivery_by_stava': '777 070 861',

    Bolt: '+48 22 153 00 08',
    Glovo: '22 536 41 60',
    JustEat: '71 707 14 11',
  },

  statusChanged: 'Status zamówienia został zmieniony',
  courierOrdered: 'Kurier został zamówiony',

  courierStatuses: {
    'pickup_cancelled': 'kurier anulował odbiór zamowienia',
    'pickup_failed': 'nie udało się odebrać zamówienia',
    'pickup_paused': 'odbiór zamówienia został wstrzymany',
    'drop_cancelled': 'kurier anulował dostawę zamowienia',
    'drop_failed': 'nie udało się dostarczyć zamówienia',
    'drop_paused': 'dostawa zamówienia został wstrzymany',
    'pickup_not_started': 'oczekiwanie na kuriera',
    'pickup_started': 'odbiór rozpoczęty',
    'pickup_on_place': 'kurier jest na miejscu',
    'pickup_finished': 'odbiór zakończony',
    'drop_not_started': 'dostawa niezaczęta',
    'drop_started': 'dostawa rozpoczęta',
    'drop_on_place': 'dostawa na miejscu',
    'drop_finished': 'dostawa zakończona',
  },

  deliveryTypes: {
    door2door: 'spotkanie przy drzwiach',
    outside: 'spotkanie na zewnątrz',
    'leave@door': 'zostaw pod drzwiami',
  },

  deliveryBy: {
    'pick_up': 'Odbiór osobisty',
    'dine_in': 'Na miejscu',
    'delivery_by_provider': 'Dostawa przez ',
    'delivery_by_us': 'Dostawa przez RebelTang',
    'delivery_by_restaurant': 'Dostawa przez restaurację',
    'delivery_by_ld': 'Dostawa przez Lets Deliver',
    'delivery_by_stava': 'Dostawa przez Stava',
    'delivery_by_stuart': 'Dostawa przez Stuart',
    'delivery_by_deligoo': 'Dostawa przez Deligoo',
    'delivery_by_woltdrive': 'Dostawa przez WoltDrive',
    null: '',
  },

  paymentMethods: {
    paid: 'opłacone',
    cash: 'gotówka',
    card: 'karta',
  },

  rejectionReasons: {
    'missing_items': 'Brak składników zamówienia',
    'too_many_orders': 'Za duża ilość zamówień',
    'out_of_order': 'Zamknięcie restauracji',
    'no_reason': 'Brak powodu',
    'canceled_by_marketplace': 'przez klienta',
    expired: 'Niezaakceptowane',
  },

  orderCanceledByMarketplace: 'Zamówienie zostało anulowane przez klienta',
  orderExpired:
    'Zamówienie zostało odrzucone ze względu na przekroczenie czasu na przyjęcie zamówienia',

  orderId: 'Numer zamówienia',

  minutes: 'minut',
  statusHeader: 'Kurier został zamówiony',
  refreshData: 'Odśwież dane',
  buttonMessage: 'Zamknij',
};
