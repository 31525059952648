import homepage from "./homepage";
import auth from "./auth";
import restaurants from "./restaurants";
import locations from "./locations";
import logs from "./logs";
import orders from "./orders";
import general from "./general";
import stats from "./stats";
import users from "./users";
import files from "./files";
import menu from "./menu";
import errors from "./errors";
import ws from "./ws";
import holidays from "./holidays";

export default {
  homepage,
  auth,
  restaurants,
  locations,
  logs,
  orders,
  general,
  stats,
  users,
  files,
  menu,
  errors,
  ws,
  holidays,
}
