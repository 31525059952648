
  import { Component, Vue } from 'vue-property-decorator'

  @Component
  export default class LangSwitcher extends Vue {
    mounted(): void {
      const lang = localStorage.getItem('lang');
      if (lang) {
        this.changeLang(lang);
      }
    }

    get langs(): string[] {
      return this.$i18n.availableLocales.map(item => item.substr(0, 2).toUpperCase());
    }

    get currentLang(): string {
      return this.$i18n.locale.substr(0, 2);
    }

    set currentLang(langNdx: string) {
      const newLang = this.$i18n.availableLocales[langNdx];

      this.changeLang(newLang);
      localStorage.setItem('lang', newLang);
    }

    changeLang(lang: string): void {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang.substr(0, 2);
    }
  }
