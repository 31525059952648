export default {
  locations: {
    header: 'Lokalizacje',
    qua: 'Ilość lokalizacji',
    restaurantsQua: 'Ilość restauracji',
    profilesNumber: 'Ilość profili',
    openedQua: 'Otwarte',
    closedQua: 'Zamknięte',
    running: 'Działające restauracje',
  },

  orders: {
    todays: 'Zamówienia z dziś',
    orders: 'Zamówienia',
    rejected: 'Odrzucone',
    specialOffer: 'Oferta Specjalna',
    freeDelivery: 'Free Delivery',
    yesterdays: 'Zamówienia z wczoraj',
  }
}
