export default {
  locations: {
    header: 'Locais',
    qua: 'Número de localizações',
    restaurantsQua: 'Número de restaurantes',
    profilesNumber: 'Número de perfis',
    openedQua: 'Aberto',
    closedQua: 'Fechado',
    running: 'Operando restaurantes',
  },

  orders: {
    todays: 'Encomendas de hoje',
    orders: 'Encomendas',
    rejected: 'Rejeitado',
    specialOffer: 'Oferta especial',
    freeDelivery: 'Entrega gratuita',
    yesterdays: 'Encomendas de ontem',
  }
}
