export default {
  add: 'Adding a menu category',
  edit: 'Edit menu categories',

  techPhotosLabel: 'Upload technical photos (max 3)',
  techDescLabel: 'Technical description',

  name: 'Name',
  type: 'Category type',
  limits: 'Quantity limits',
  from: 'from',
  to: 'to',
  reset: 'zero price products',

  productsCat: 'product category',
  modsCat: 'modifier category',

  addItem: 'Creating a menu item',
  editItem: 'Edit menu item',
  choose: 'Select existing products',
  addNew: 'or add a new one',
  products: 'Products',

  photo: 'Photo',
  desc: 'Description',
  qua: 'Weight/quantity',
  price: 'Price',
  mods: 'Modifiers',

  menu: 'Menu',
  confirmRemove: 'Are you sure you want to delete the selected item?',
  confirmRemoveCategory:
    'Are you sure you want to delete the selected category and all the items it contains?',

  importCsv: 'Select the CSV file from the menu to import',
  confirmImport:
    'Importing a new menu will delete the current menu (including photos and modifiers). Are you sure you want to continue?',
  chhoseCSV: 'Please select a CSV file',

  accessibility: 'Menu availability',

  top: 'Top dishes',

  catRemoved: 'The category has been deleted',
  catAdded: 'Category has been added',
  itemRemoved: 'The menu item has been deleted',
  itemAdded: 'Menu item has been added',
  itemAddedToCat: 'Products have been added to categories',
  menuImported: 'The menu has been imported',

  copyCategory: 'Copy menu category',
  categoryCopied: 'Category has been copied',
  categoryCopiedError: 'Copying category failed',
  copyWithMods: 'Copy with mods',

  excludeFromSync: 'exclude from sync',
  requiredMenuItem: 'require item',

  turnOnSelected: 'turn on selected',
  turnOffSelected: 'turn off selected',
  turnOffPermanent: 'permanently',
  turnOffToday: 'until end of the day',
};
