export interface Restaurant {
  id: number;
  name: string;
  logo?: string;
  desc?: string;
  prepTime?: number;
  type: RestaurantType;
  country: string;
  brandId: number;
  external: boolean;
}

export enum RestaurantType {
  restaurant = 'restaurant',
  shop = 'shop'
}
