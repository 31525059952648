export default {
  nextPage: 'próxima página',
  areYouSure: 'Tem certeza?',

  newVersion: 'Uma nova versão do aplicativo está disponível. Clique para atualizar.',
  offline: 'Você está offline. Verifique sua conexão com a Internet.',

  all: 'tudo',

  cancel: 'Cancelar',
  save: 'Salve',

  landscape: 'Gire o tablet para aproveitar ao máximo o aplicativo',

  youROffline: 'você está offline',
  checkConnection: 'Verifique a sua conexão com a internet',

  interaction: 'Confirme a identificação do tablet no dispositivo interativo <br /><br />Toque',

  404: 'Esta página não existe',
  500: 'Um erro ocorreu durante o processamento do seu pedido. Por favor, tente novamente em um momento',

  country: 'País',
  notificationLanguage: 'Idioma de notificações',
  type: 'Tipo',
  name: 'Nome',

  dataSaved: 'Data has been saved',

  turningOn: 'Your restaurant {name} will be turned on at {time}',
};
