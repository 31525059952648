import { accessList } from "@/configs";
import { Role } from "@/interfaces";

export const isLogged = (): boolean => localStorage.getItem('at') !== null;

export const getLocale = (): string => localStorage.getItem('lang') || 'en-US';

export const getLang = (): string => getLocale().slice(0, 2);

export function hasAccess(userRole: Role, resourceName: string): boolean {
  return userRole === 'admin' || accessList[resourceName]?.includes(userRole);  
}
