import { Nullable, Restaurant } from '@/interfaces';
import { CopyRestaurantPayload } from '@/store/interfaces';
import apiService from './api';

class RestaurantsService extends apiService{
  findAll(): Promise<Nullable<Restaurant[]>> {
    return this.get('restaurants');
  }

  findOne(id: number): Promise<Nullable<Restaurant>> {
    return this.get(`restaurants/${ id }`);
  }

  remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`restaurants/${ id }`);
  }

  async update(data: Restaurant): Promise<Nullable<Restaurant>> {
    return this.patch(`restaurants/${ data.id }`, this.dataToFormData(data), this.fileUploadHeaders());
  }

  add(data: Partial<Restaurant>): Promise<Nullable<Restaurant>> {
    return this.post(`restaurants/add/${ data.country }/${ data.brandId }`, this.dataToFormData(data), this.fileUploadHeaders());
  }

  copy(data: CopyRestaurantPayload): Promise<Nullable<Restaurant>> {
    return this.post(`restaurants/copy/${ data.baseId }`, data);
  }
}

export default new RestaurantsService
