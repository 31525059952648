export default {
  nextPage: 'наступна сторінка',
  areYouSure: 'Ви впевнені?',

  newVersion: 'Доступна нова версія програми. Натисніть, щоб обновити.',
  offline: "Ви offline.Перевірте інтернет з'єднання.",

  all: 'всі',

  cancel: 'Скасувати',
  save: 'Зберегти',

  landscape: 'Поверніть планшет, щоб ефективніше користуватись програмою',

  youROffline: 'ви offline',
  checkConnection: 'перевірте підключення до інтернету ',

  interaction:
    'Підтвердьте ідентифікацію планшета на інтерактивному пристрої <br /> <br /> Натисніть',

  404: 'Дана сторінка не існує',
  500: 'Сталася помилка при обробці Вашого запиту. Повторіть спробу ще раз',

  country: 'Країна',
  notificationLanguage: 'мова сповіщень',
  type: 'Тип',
  name: 'Назва',

  dataSaved: 'Дані були збережені',

  turningOn: 'Your restaurant {name} will be turned on at {time}',
};
