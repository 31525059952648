import { formatPrice } from '@/helpers/price';
import { OrderItem, OrderItemMod } from '@/interfaces';
import { OrderItemModModel } from '.';

export class OrderItemModel implements OrderItem {
  id!: number;
  itemId!: number;
  qua!: number;
  value!: number;
  mods?: OrderItemMod[];
  comments?: string | undefined;

  constructor(data: Partial<OrderItem>, private currencyFormat: string) {
    Object.assign(this, data);

    this.value = +this.value;

    this.mods = this.mods && this.mods.map(item => new OrderItemModModel(item, this.currencyFormat));
  }

  getValueWithCurrency(): string {
    return formatPrice(this.value, this.currencyFormat);
  }

  getSingleValueWithCurrency(): string {
    return formatPrice(this.value, this.currencyFormat);
  }
}
