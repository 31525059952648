export const toLocale = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}

export const toLocalDate = (dateString: string, locale: string, short = false): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString(locale, { month: 'long', day: 'numeric', year: short ? undefined : 'numeric' });
}

export const toLocaleTime = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleTimeString();
}

export function getDiff(date1: string, date2?: string): number {
  const dateToCompare = date2 ? new Date(date2) : new Date;
  const diff = new Date(date1).getTime() - new Date(dateToCompare).getTime();
  return Math.floor(diff / (1000 * 60));
}

export function getCurrentDate(): string {
  const now = new Date();
  
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}
