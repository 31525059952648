
  import { Component, Prop, Vue } from "vue-property-decorator";

  import { ConceptModel } from "@/models";
  import TurningOn from './TurningOn.vue';
  import { toLocale } from "@/helpers/date";

  @Component({ components: { TurningOn } })
  export default class TurningOnBannerGroup extends Vue {
    @Prop({ required: true }) readonly concepts!: ConceptModel[];

    getTime(concept: ConceptModel): string {
      const time = concept.storeStatus && Object.values(concept.storeStatus)[0]?.until;
      return time ? toLocale(time) : '';
    }
  }
