import { Nullable, Resource, ResourceType } from '@/interfaces';
import { ResourcesService } from '@/services';
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class extends VuexModule {
  public resources: Resource[] = [];

  get resosurcesMap(): Record<number, Resource> {
    return this.resources.reduce(
      (all, curr) => ({
        ...all,
        [curr.id]: curr,
      }),
      {}
    );
  }

  @MutationAction({ mutate: ['resources'] })
  public async getResources(type?: ResourceType): Promise<{ resources: Resource[] }> {
    return {
      resources: (await ResourcesService.findAll(type)) || [],
    };
  }

  @Action({ commit: 'removeResourceMutation' })
  public async removeResource(id: number): Promise<Nullable<number>> {
    if (await ResourcesService.remove(id)) {
      this.context.commit('notification/showNotification', 'general.dataSaved', { root: true });

      return id;
    }

    return null;
  }

  @Mutation
  public removeResourceMutation(id: number): void {
    this.resources = this.resources.filter((item) => item.id !== id);
  }

  @Action({ commit: 'updateResourceMutation' })
  public async updateResource(data: Resource): Promise<Nullable<Resource>> {
    if (await ResourcesService.update(data)) {
      return data;
    }
    return null;
  }

  @Mutation
  updateResourceMutation(item: Resource): void {
    if (!item) {
      return;
    }

    const resources = [...this.resources];
    const index = resources.findIndex((_item) => _item.id === item.id);

    if (index !== -1) {
      resources[index] = item;
    }

    this.resources = resources;
  }

  @Action({ commit: 'addResourceMutation' })
  public async addResource(data: Partial<Resource>): Promise<Nullable<Resource>> {
    const item = await ResourcesService.add(data);
    if (item) {
      this.context.commit('notification/showNotification', 'general.dataSaved', { root: true });
      return item;
    }

    return null;
  }

  @Mutation
  addResourceMutation(item: Resource): void {
    this.resources.push(item);
  }
}
