export default {
  rawData: 'Order data received',
  statusOrders: 'orders',

  orderCanceled:
    'Your order has been cancelled. Unfortunately {source} does not support automatic cancellation of orders, so you will need to cancel your order via the call centre',
  orderid: 'Order ID:',
  phone: 'phone number:',

  orderCanceledCourier:
    'Your order has been cancelled. Remember to call the courier company and cancel the courier, otherwise delivery charges will be applied.',

  courierProblem: 'Courier error',
  status: 'status',
  contactCourier: 'Contact the courier company to clarify the matter',

  clientData: 'Details of the ordere',
  deliveryData: 'Delivery data',
  deliveryAddress: 'Delivery address',
  problemWithGettingAddress:
    'Failed to download full delivery address. Please contact the customer by phone to determine a delivery address.',
  comments: 'Notes',

  predictedPickupTime: 'Predicted pickup time',

  orderDetails: 'Order details',
  disponsable: 'Add cutlery etc.',
  orderStatus: 'Order status',
  orderSource: 'Order source',
  paymentMethod: 'Payment method',
  orderDate: 'Order date',
  deliveryType: 'Order collection',
  payByCash: 'Payment on delivery',
  courier: 'Courier',

  statusReady: 'ready',
  statusDone: 'completed',

  orderCourierConfirm: 'Are you sure you want to order a courier?',

  invoiceData: 'Invoice details',
  invoiceCompany: 'Company name',
  invoiceAddress: 'Address',
  invoiceTaxID: 'NIP',

  products: 'Ordered products',
  allergies: 'Allergies',
  orderComment: 'Comment on the order',

  cancelBtn: 'cancel order',

  paymentBreakdown: 'Payment breakdown',
  subtotal: 'Subtotal',
  specialOffers: 'Special Offers',
  orderValue: 'Contract value after promotions',
  deliveryCost: 'Delivery cost',
  summary: 'Summary',

  newOrder: 'you have new orders',

  location: 'location',
  concept: 'koncepty',
  restaurant: 'restaurant',

  statuses: {
    new: 'new',
    processing: 'accepted',
    'ready_for_pickup': 'ready for pickup',
    finished: 'completed',
    'finished_auto': 'Completed automatically',
    rejected: 'rejected',
  },

  source: 'aggregator',

  sources: {
    Glovo: 'Glovo',
    UberEats: 'UberEats',
    JustEat: 'JustEat',
    Wolt: 'Wolt',
    Bolt: 'Bolt',
    SocialFood: 'SocialFood',
  },

  phones: {
    'delivery_by_ld': '22 185 56 03 / 518 343 803',
    'delivery_by_stava': '777 070 861',

    Bolt: '+48 22 153 00 08',
    Glovo: '22 536 41 60',
  },

  statusChanged: 'Order status has been changed',
  courierOrdered: 'The courier has been ordered',

  courierStatuses: {
    'pickup_cancelled': 'courier cancelled pick up of order',
    'pickup_failed': 'order could not be collected',
    'pickup_paused': 'receipt of order suspended',
    'drop_cancelled': 'courier cancelled the delivery of the order',
    'drop_failed': 'the order could not be delivered',
    'drop_paused': 'delivery of the order has been suspended',
    'pickup_not_started': 'pickup not started',
    'pickup_started': 'pickup started',
    'pickup_on_place': 'pickup on place',
    'pickup_finished': 'pickup finished',
    'drop_not_started': 'drop not started',
    'drop_started': 'drop started',
    'drop_on_place': 'drop on place',
    'drop_finished': 'drop finished',
  },

  deliveryTypes: {
    door2door: 'doorstep meeting',
    outside: 'meeting outside',
    'leave@door': 'leave at the door',
  },

  deliveryBy: {
    'pick_up': 'Personal pick up',
    'dine_in': 'On site',
    'delivery_by_provider': 'Delivery by ',
    'delivery_by_us': 'Delivery by RebelTang',
    'delivery_by_restaurant': 'Restaurant delivery',
    'delivery_by_ld': 'Delivery by Lets Deliver',
    'delivery_by_stava': 'Delivery by Stava',
    'delivery_by_stuart': 'Delivery by Stuart',
    'delivery_by_deligoo': 'Delivery by Deligoo',
    'delivery_by_woltdrive': 'Delivery by WoltDrive',
    null: '',
  },

  paymentMethods: {
    paid: 'paid',
    cash: 'cash',
    card: 'card',
  },

  rejectionReasons: {
    'missing_items': 'Lack of order components',
    'too_many_orders': 'Too many orders',
    'out_of_order': 'Restaurant closure',
    'no_reason': 'No reason',
    'canceled_by_marketplace': 'By Client',
    expired: 'Not accepted',
  },

  orderCanceledByMarketplace: 'Your order has been canceled by customer',
  orderExpired: 'The order has been canceled due to exceeding acceptation time',

  orderId: 'Order no',

  minutes: 'minutes',

  statusHeader: 'Courier has been ordered',
  buttonMessage: 'Close',
};
