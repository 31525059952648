/* eslint-disable @typescript-eslint/camelcase */
export const statusColors = {
    new: 'green',
    processing: 'orange',
    finished: 'blue',
    rejected: 'red',
    ready_for_pickup: 'purple',
    finished_auto: 'cyan'
};
/* eslint-enable @typescript-eslint/camelcase */
