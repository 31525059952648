import { OrderStatus, OrderStatusHistory } from "@/interfaces";
import { toLocale } from '@/helpers/date';

export class OrderStatusHistoryModel implements OrderStatusHistory {
  id!: number;
  status!: OrderStatus;
  createdAt!: string;

  public constructor(data: Partial<OrderStatusHistory>) {
    Object.assign(this, data);

    this.createdAt = toLocale(this.createdAt);
  }
}
