import { Nullable, Resource, ResourceType } from '@/interfaces';
import apiService from './api';

class ResourcesService extends apiService {
  findAll(type?: ResourceType): Promise<Nullable<Resource[]>> {
    return this.get(`resources${type ? '/' + type : ''}`);
  }

  add(data: Partial<Resource>): Promise<Nullable<Resource>> {
    return this.post('resources', data);
  }

  remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`resources/${id}`);
  }

  update(data: Resource): Promise<Nullable<Resource>> {
    return this.patch(`resources/${data.id}`, data);
  }
}

export default new ResourcesService();
