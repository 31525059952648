import { MenuCategory, MenuItem, MenuItemType, TurnItemOffUntil } from '@/interfaces';

export class MenuItemModel implements MenuItem {
  id!: number;
  name!: string;
  categories?: MenuCategory[] | undefined;
  modifiers?: number[] | undefined;
  active?: boolean | undefined;
  until?: TurnItemOffUntil;
  exclude?: boolean | undefined;
  type!: MenuItemType;
  components?: string;
  price!: number;
  photo?: string | undefined;
  desc?: string | undefined;
  size?: string | undefined;

  public constructor(item: MenuItem) {
    Object.assign(this, item);

    this.modifiers = item.categories?.map(cat => cat.id)
  }

  getComponents(): number[] {
    return this.components?.length
      ? this.components.split(',').map(componentId => +componentId)
      : [];
  }
}
