import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { MenuCategory, MenuItem, Nullable } from '@/interfaces';
import { AddCategoryPayload, RemoveItemPayload, AddItemPayload, AddItemsToCategoryPayload, ChangeOrderPayload, UpdateItemPayload, UploadMenuPayload, CopyMenuCategoryPayload, RegenerateThumbnailsPayload } from './interfaces';

import { MenuService, ProvidersService } from '@/services';
import { MenuModel } from '@/models';

@Module({ namespaced: true })
export default class extends VuexModule {
  public menu: MenuModel = new MenuModel([]);

  @Action({ commit: 'setMenu' })
  async getByRestaurant(id: number): Promise<Nullable<MenuModel>> {
    return MenuService.findByRestaurant(id);
  }

  @Action({ commit: 'removeCategoryMutation' })
  async removeCategory(id: number): Promise<Nullable<number>> {
    if (await MenuService.removeCategory(id)) {
      this.context.commit('notification/showNotification', 'menu.catRemoved', { root: true });

      return id;
    }
    return null;
  }

  @Action({ commit: 'updateCategoryMutation' })
  async updateCategory(item: MenuCategory): Promise<Nullable<MenuCategory>> {
    if (await MenuService.updateCategory(item)) {
      return item;
    }
    return null;
  }

  @Action({ commit: 'addCategoryMutation' })
  async addCategory({ restaurantId, data}: AddCategoryPayload): Promise<Nullable<MenuCategory>> {
    const item = await MenuService.addCategory(restaurantId, data);
    if (item) {
      this.context.commit('notification/showNotification', 'menu.catAdded', { root: true });
      return item;
    }
    return null;
  }

  @Action({ commit: 'removeItemMutation' })
  async removeItem({ id, categoryId }: RemoveItemPayload): Promise<Nullable<{ id: number; categoryId: number }>> {
    if (await MenuService.removeItem(id, categoryId)) {
      this.context.commit('notification/showNotification', 'menu.itemRemoved', { root: true });

      return { id, categoryId };
    }
    return null;
  }

  @Action({ commit: 'updateItemMutation' })
  async updateItem({ categoryId, data }: UpdateItemPayload): Promise<Nullable<{ categoryId: number; data: MenuItem }>> {
    if (await MenuService.updateItem(data)) {
      return { categoryId, data };
    }
    return null;
  }

  @Action({ commit: 'addItemMutation' })
  async addItem({ categoryId, data }: AddItemPayload): Promise<Nullable<{ categoryId: number; item: MenuItem }>> {
    const item = await MenuService.addItem(categoryId, data);
    if (item) {
      this.context.commit('notification/showNotification', 'menu.itemAdded', { root: true });
      
      return { categoryId, item };
    }
    return null;
  }

  @Action({ commit: 'addItemsToCategoryMutation' })
  async addItemsToCategory({ categoryId, productsIds }: AddItemsToCategoryPayload): Promise<Nullable<{ categoryId: number; productsIds: number[] }>> {
    const item = await MenuService.addItemsToCategory(categoryId, productsIds);
    if (item) {
      this.context.commit('notification/showNotification', 'menu.itemAddedToCat', { root: true });
      return { categoryId, productsIds };
    }
    return null;
  }

  @Action
  async uploadMenu({ restaurantId, file }: UploadMenuPayload): Promise<boolean> {
    const item = await MenuService.uploadMenu(restaurantId, file);
    if (item) {
      this.context.commit('notification/showNotification', 'menu.menuImported', { root: true });
      return true;
    }

    return false;
  }

  @Action
  changeOrder({ type, ids, parentId }: ChangeOrderPayload): void {
    MenuService.changeOrder(type, ids, parentId);
  }

  @Action
  regenerateThumbnails({ type, conceptId }: RegenerateThumbnailsPayload): void {
    ProvidersService.regenerateThumbnails(type, conceptId);
  }

  @Mutation
  setMenu(menu: MenuModel): void {
    this.menu = menu;
  }

  @Mutation
  removeCategoryMutation(id: number): void {
    const categories: MenuCategory[] = this.menu.getCategories().filter(item => item.id !== id);
    this.menu = new MenuModel(categories);
  }

  @Mutation
  updateCategoryMutation(item: MenuCategory): void {
    const categories: MenuCategory[] = this.menu.getCategories();

    const index = categories.findIndex(_item => _item.id === item.id);
    categories[index] = {...categories[index], ...item, items: categories[index].items};

    this.menu = new MenuModel(categories);
  }

  @Mutation
  addCategoryMutation(item: MenuCategory): void {
    const categories = [
      ...this.menu.getCategories(),
      item
    ]
    this.menu = new MenuModel(categories);
  }

  @Mutation
  removeItemMutation({ id, categoryId }: { id: number; categoryId: number }): void {
    const categories: MenuCategory[] = this.menu.getCategories();

    const categoryNdx = categories.findIndex(item => item.id === categoryId);
    categories[categoryNdx].items = categories[categoryNdx].items.filter(item => item.id !== id);

    this.menu = new MenuModel(categories);
  }

  @Mutation
  updateItemMutation({ categoryId, data }: { categoryId: number; data: MenuItem }): void {
    const categories: MenuCategory[] = this.menu.getCategories();

    const category = categories.find(item => item.id === categoryId);
    if (category) {
      const itemIndex = category.items.findIndex(_item => _item.id === data.id);
      category.items[itemIndex] = {...category.items[itemIndex], ...{
        ...data,
        components: Array.isArray(data.components) ? data.components.join(',') : data.components
      }};
    }

    this.menu = new MenuModel(categories);
  }

  @Mutation
  addItemMutation({ categoryId, item }: { categoryId: number; item: MenuItem }): void {
    const categories: MenuCategory[] = [ ...this.menu.getCategories()];

    const categoryNdx = categories.findIndex(item => item.id === categoryId);
    if (!categories[categoryNdx].items) {
      categories[categoryNdx].items = [];
    }
    categories[categoryNdx].items.push(item);

    this.menu = new MenuModel(categories);
  }

  @Mutation
  addItemsToCategoryMutation({ categoryId, productsIds }: { categoryId: number; productsIds: number[] }): void {
    const menu: MenuCategory[] = [ ...this.menu.getCategories()];

    const products = this.menu.getAllProducts().filter(product => productsIds.includes(product.id));
    const categoryNdx = menu.findIndex(item => item.id === categoryId);
    menu[categoryNdx].items = [...(menu[categoryNdx].items || []), ...products];

    this.menu = new MenuModel(menu);
  }

  @Action
  async copyMenuCategory(payload: CopyMenuCategoryPayload): Promise<void> {
    const item = await MenuService.copyMenuCategory(payload);
    this.context.commit(`notification/${ item ? 'showNotification' : 'showError' }`, `menu.categoryCopied${ !item ? 'Error' : '' }`, { root: true });
  }
}
