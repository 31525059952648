export enum FileType {
  'restaurant' = 'restaurant',
  'location' = 'location'
}

export interface File {
  id: number;
  type: FileType;
  parentId: number;
  name: string;
  file: string;
}
