import plPL from './pl-PL'
import enUS from './en-US'
import ptPT from './pt-PT'
import ukUA from './uk-UA'

export default {
  'en-US': enUS,
  'pl-PL': plPL,
  'pt-PT': ptPT,
  'ua-UA': ukUA
}
