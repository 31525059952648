import apiService from './api';
import { Holiday, Nullable } from '@/interfaces';
import { AddHolidayPayload } from '@/store/interfaces';

class HolidaysService extends apiService{
  findAll(): Promise<Nullable<Holiday[]>> {
    return this.get('holidays');
  }

  remove(id: number): Promise<Nullable<boolean>> {
    return this.delete(`holidays/${ id }`);
  }

  add(data: AddHolidayPayload): Promise<Nullable<Holiday[]>> {
    return this.post('holidays', data);
  }
}

export default new HolidaysService;
