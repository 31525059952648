/* eslint-disable @typescript-eslint/camelcase */
export default {
  locations: 'Lokalizacje',
  filterCountry: 'filtruj państwa',
  filterCity: 'filtruj miejscowości',
  filterOptions: 'filtry',
  removeConfirm: 'Czy na pewno chcesz usunąć wybraną lokalizację oraz wszystkie jej dane?',
  filterClosed: 'Zamkniete',
  filterRegions: 'regiony',

  turnOff: 'wyłącz lokalizację',
  turnOn: 'włącz lokalizację',

  confirmOn: 'Czy na pewno chcesz włączyć lokalizację?',
  confirmOff: 'Czy na pewno chcesz wyłączyć lokalizację?',

  add: 'Tworzenie nowej lokalizacji',
  edit: 'Edycja danych lokalizacji',

  companyName: 'Nazwa firmy',
  street: 'Ulica',
  no: 'Numer',
  city: 'Miasto',
  zip: 'Kod pocztowy',
  contact: 'Dane kontaktowe',
  comment: 'Komentarz',
  guideline: 'Instrukcja dla kuriera',

  deliveries: 'Edycja dostawców',
  deliveryResp: 'Odpowiedzialny za dostawę',

  name: 'Nazwa',

  contactPerson: 'Osoba kontaktowa:',

  removed: 'Lokalizacja została usunięta',
  added: 'Lokalizacja została dodana',

  countries: {
    pl: 'Polska',
    ua: 'Ukraina',
    pt: 'Portugalia',
    cy: 'Cypr',
  },

  languages: {
    pl: 'Polski',
    ua: 'Ukraiński',
    en: 'Angielski',
  },

  couriers: {
    delivery_by_provider: 'marketplace',
    delivery_by_restaurant: 'restauracja',
    delivery_by_ld: 'Lets Deliver',
    delivery_by_stava: 'Stava',
    delivery_by_stuart: 'Stuart',
    delivery_by_deligoo: 'Deligoo',
  },

  phone: 'Nr telefonu',

  addHolidays: 'Dodaj urlop',
  holidayAdded: 'Urlop został dodany',

  restaurantName: 'Nazwa restauracji',
  allowSmsNotifications: 'Zezwalaj na powiadomienia SMS',
  notificationPhone: 'Numer telefonu do otrzymywania powiadomień SMS',
};
