export default {
  nextPage: 'kolejna strona',
  areYouSure: 'Na pewno?',

  newVersion: 'Dostępna jest nowa wersja aplikacji. Kliknij, aby odświeżyć.',
  offline: 'Jesteś offline. Sprawdź swoje połaczenie z Internetem.',

  all: 'wszystkie',

  cancel: 'Anuluj',
  save: 'Zapisz',

  landscape: 'Obróć tablet, aby w pełni korzystać z aplikacji',

  youROffline: 'jesteś offline',
  checkConnection: 'sprawdź swoje połaczenie z Internetem',

  interaction: 'Potwierdź identyfikacje tabletu w urządzeniu interaktywnym <br /><br /> Dotknij',

  404: 'Podana strona nie istnieje',
  500: 'Wystąpił błąd podczas przetwarzania zapytania. Spróbuj ponownie za chwilę',

  country: 'Kraj',
  notificationLanguage: 'Język notyfikacji',
  type: 'Typ',
  name: 'Nazwa',

  dataSaved: 'Dane zostały zapisane',

  turningOn: 'Twoja restauracja {name} będzie ponownie aktywna o {time}',
};
