export default {
  login: 'Logowanie',
  email: 'Adres e-mail',
  password: 'Hasło',
  dontRemember: 'nie pamiętasz hasła?',
  loginBtn: 'Zaloguj',

  remind: 'Przypominanie hasła',
  reset: 'Resetuj hasło',

  setNewPassword: 'Ustawianie nowego hasła',
  repeatPassword: 'Powtórz hasło',
  save: 'Zapisz',

  noCredentials: 'Nie masz wystarczających uprawnień',
  wrongData: 'Podane dane są niepoprawne',
  instructions: 'Instrukcje ustawienia nowego hasła zostały wysłane na podany e-mail',
  noUser: 'Podany e-mail nie istnieje w naszej bazie',
  passChanged: 'Hasło zostało zmienione',
  passChangedError: 'Nie udało się zmienić hasła',
}
