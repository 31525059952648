import { removeDuplicates, sortStringByName } from "@/helpers/array";
import { AllStoreStatuses, Status } from "@/interfaces";
import { ConceptModel } from ".";

export class LocationStatusModel {
  constructor(private readonly name: string, private concepts: ConceptModel[]) {}

  getConcepts(): ConceptModel[] {
    return this.concepts;
  }

  getErrors(): string[] {
    const errors: string[] = [];

    this.getConcepts().forEach(item => {
      if (!item.hasWorkingHours()) {
        errors.push('missing WorkingHours');
      }
  
      if (!item.last_sync) {
        errors.push('missing sync');
      }

      item.storeStatus && Object.keys(item.storeStatus).forEach(provider => {
        if (!item.storeStatus?.[provider].status) {
          errors.push(`restaurant is turned off in ${ provider }`);
        }
      });
    });

    return errors;
  }

  getErrorsQua(): number {
    return this.getErrors().length;
  }

  getWarnings(): string[] {
    const warnings: string[] = [];

    this.getConcepts().forEach(item => {
      if (!item.bolt_storeId) {
        warnings.push('missing Bolt storeId');
      }
  
      if (!item.glovo_storeId) {
        warnings.push('missing Glovo storeId');
      }
  
      if (!item.own_storeId) {
        warnings.push('missing SocialFood storeId');
      }
  
      if (!item.stava_storeId) {
        warnings.push('missing Stava storeId');
      }
  
      if (!item.ue_storeId) {
        warnings.push('missing UberEats storeId');
      }
  
      if (!item.wolt_storeId) {
        warnings.push('missing Wolt storeId');
      }
    });

    return warnings;
  }

  getWarningsQua(): number {
    return this.getWarnings().length;
  }

  getErrorsAndWarnings(): string[] {
    return [
      ...this.getErrors(),
      ...this.getWarnings()
    ]
  }

  hasAllRestaurantsActive(): boolean {
    const statuses = this.concepts
      .map(concept => concept.storeStatus)
      .filter(data => data)
      .flatMap(storeStatuses => storeStatuses && Object.values(storeStatuses))
      .map((data: Status | undefined) => data?.status);

    return !!(statuses.length && statuses.every(data => data === true || data === undefined));
  }
}

export class LocationsStatusAggregator {
  private locations: LocationStatusModel[] = [];;

  constructor(concepts: ConceptModel[], restaurantsStatus: AllStoreStatuses) {
    concepts.forEach(concept => concept.setStoreStatus(restaurantsStatus[concept.id]));

    const locations = (concepts
      .map(concept => concept.location?.name)
      .filter(removeDuplicates)
      .sort(sortStringByName) as string[]);

    this.locations = locations.map(location => 
      new LocationStatusModel(location, concepts.filter(concept => concept.location?.name === location))
    );
  }

  
  getLocations(problemsToFilter?: string[], locationsInRegions?: number[] | null): LocationStatusModel[] {
    let filteredLocations = !problemsToFilter?.length
      ? this.locations
      : this.locations.filter(location => location.getErrorsAndWarnings().findIndex(item => problemsToFilter.includes(item)) !== -1);

    if (locationsInRegions && locationsInRegions.length > 0) {
      filteredLocations = filteredLocations.filter(location => {
        return location.getConcepts().some(concept => locationsInRegions.includes(concept.locationId));
      });
    }

    return filteredLocations;
  }

  
  getErrorsList(): string[] {
    return this.locations
      .reduce((all: string[], item) => ([...all, ...item.getErrorsAndWarnings()]), [])
      .sort(sortStringByName);
  }
}
