export default {
  add: 'Додавання категорії меню',
  edit: 'Редагувати категорії меню',

  techPhotosLabel: 'Завантажити технічні фотографії (макс. 3)',
  techDescLabel: 'Технічний опис',

  name: 'Назва',
  type: 'Категорія',
  limits: 'Обмеження кількості',
  from: 'від',
  to: 'до',
  reset: 'безкоштовно',

  productsCat: 'категорія продукту',
  modsCat: 'категорія модифікатора',

  addItem: 'Створення пункту меню',
  editItem: 'Редагувати пункт меню',
  choose: 'Обрати наявні продукти',
  addNew: 'або додати новий',
  products: 'Продукти',

  photo: 'Фото',
  desc: 'Опис',
  qua: 'Вага/кількість',
  price: 'Ціна',
  mods: 'Модифікатори',

  menu: 'Меню',
  confirmRemove: 'Ви впевнені, що хочете видалити вибраний елемент?',
  confirmRemoveCategory:
    'Ви впевнені, що хочете видалити вибрану категорію та всі елементи, які вона містить?',

  importCsv: 'Виберіть файл CSV з меню для імпорту',
  confirmImport:
    'Імпортування нового меню призведе до видалення поточного (включаючи фотографії та модифікатори). Ви впевнені, що хочете продовжити?',
  chhoseCSV: 'Виберіть файл CSV',

  accessibility: 'Доступно в меню',

  top: 'Топ страв',

  catRemoved: 'Категорію видалено',
  catAdded: 'Категорію додано',
  itemRemoved: 'Пункт меню видалено',
  itemAdded: 'Пункт меню додано',
  itemAddedToCat: 'Продукти додано до категорій',
  menuImported: 'Меню імпортовано',

  turnOnSelected: 'увімкнути вибрані страви',
  turnOffSelected: 'вимкнути вибрані страви',
  turnOffPermanent: 'на невизначений час',
  turnOffToday: 'до кінця робочого дня',
  copyCategory: 'Скопіювати категорію меню',
  categoryCopied: 'Категорія меню скопійована ',
  categoryCopiedError: 'Помилка при копіюванні категорії',
  copyWithMods: 'Копіювати із атрибутами',

  excludeFromSync: 'виключити із синхронізації',
  requiredMenuItem: 'потрібна посада',
};
