export enum NotificationType {
  success = 'success',
  error = 'error'
}

export enum MessageBoxColor {
  blue = 'blue'
}

export interface MessageBox {
  message: string;
  color: MessageBoxColor;
}
