export default {
  locations: {
    header: 'Locations',
    qua: 'Number of locations',
    restaurantsQua: 'Number of restaurants',
    profilesNumber: 'Number of profiles',
    openedQua: 'Open',
    closedQua: 'Closed',
    running: 'Running restaurants'
  },

  orders: {
    todays: 'Orders today',
    orders: 'Orders',
    rejected: 'Rejected',
    specialOffer: 'Special Offer',
    freeDelivery: 'Free Delivery',
    yesterdays: 'Orders from yesterday',
  }
}
