import { MenuCategory, MenuCategoryType } from '@/interfaces';
import { MenuItemModel } from '.';

export class MenuCategoryModel implements MenuCategory {
  id!: number;
  name!: string;
  items!: MenuItemModel[];
  type!: MenuCategoryType;
  value!: number;

  public constructor(category: MenuCategory) {
    Object.assign(this, category);
    this.items = this.items?.map(item => new MenuItemModel(item));
  }
}
