import { Nullable, Restaurant } from '@/interfaces';
import { RestaurantModel } from '@/models';
import { RestaurantsService } from '@/services';
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { CopyRestaurantPayload } from './interfaces';

@Module({ namespaced: true })
export default class RestaurantStore extends VuexModule {
  public restaurants: Restaurant[] = [];

  get getAllRestaurants(): Restaurant[] {
    return this.restaurants;
  }

  @MutationAction({ mutate: ['restaurants'] })
  public async getRestaurants(): Promise<{ restaurants: Restaurant[] }> {
    return {
      restaurants: (await RestaurantsService.findAll())?.map(restaurant => new RestaurantModel(restaurant)) || []
    }
  }

  @Action({ commit: 'removeRestaurantMutation' })
  public async removeRestaurant(id: number): Promise<Nullable<number>> {
    if (await RestaurantsService.remove(id)) {
      this.context.commit('notification/showNotification', 'restaurants.removed', { root: true });

      return id;
    }

    return null;
  }

  @Mutation
  public removeRestaurantMutation(id: number): void {
    this.restaurants = this.restaurants.filter(item => item.id !== id);
  }

  @Action({ commit: 'updateRestaurantMutation' })
  public async updateRestaurant(data: Restaurant): Promise<Nullable<Restaurant>> {
    return RestaurantsService.update(data);
  }

  @Mutation
  updateRestaurantMutation(item: Restaurant): void {
    if (!item) {
      return;
    }

    const restaurants = [ ...this.restaurants ];
    const index = restaurants.findIndex(_item => _item.id === item.id);

    if (index !== -1 ) {
      restaurants[index] = item;
    }

    this.restaurants = restaurants;
  }

  @Action({ commit: 'addRestaurantMutation' })
  public async addRestaurant(data: Partial<Restaurant>): Promise<Nullable<Restaurant>> {
    const item = await RestaurantsService.add(data);
    if (item) {
      this.context.commit('notification/showNotification', 'restaurants.added', { root: true });
      return item
    }

    return null;
  }

  @Mutation
  addRestaurantMutation(item: Restaurant): void {
    this.restaurants.push(item);
  }

  @Action
  public async getRestaurant(id: number): Promise<Nullable<RestaurantModel>> {
    const restaurant = await RestaurantsService.findOne(id);
    return restaurant ? new RestaurantModel(restaurant) : null;
  }

  @Action({ commit: 'addRestaurantMutation' })
  public async copyRestaurant(data: CopyRestaurantPayload): Promise<Nullable<Restaurant>> {
    const restaurant = await RestaurantsService.copy(data);
    if (restaurant) {
      this.context.commit('notification/showNotification', 'restaurants.added', { root: true });
      
      return new RestaurantModel(restaurant);
    }

    return null;
  }
}
