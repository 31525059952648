export default {
  removeConfirm: 'Ви впевнені, що хочете видалити вибраного користувача?',

  edit: 'Редагування',
  add: 'Додавання',
  user: 'користувач',
  admin: 'адміністратор',

  firstname: 'Ім`я',
  lastname: 'Прізвище',
  email: 'електронна адреса',
  role: 'Роль',

  users: 'Користувачі',

  removed: 'Користувача видалено',
  updated: 'Користувача оновлено',
  added: 'Користувача додано',

  limitations: 'Обмежити доступ до:',

  roles: {
    admin: 'адміністратор',
    country: 'менеджер з розвитку іноземного ринку',
    regional: 'регіональний менеджер',
    brand: 'бренд менеджер',
  },
}
