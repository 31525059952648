import { Nullable } from '@/interfaces';
import apiService from './api';

class WsService extends apiService{
  async sendInfoAboutNewVersion(hard: boolean): Promise<Nullable<boolean>> {
    return this.post('ws/newVersion', { hard });
  }

  async sendTestOrder(orderId: number): Promise<Nullable<boolean>> {
    return this.get('ws/order/' + orderId);
  }
}

export default new WsService
